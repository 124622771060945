import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { rgba } from 'polished';
import { StatusInterfaces } from '@libs';
import { notifications } from '@features/notifications';
import * as getData from './modules/getdata';
import * as effects from './modules/effects';
import { GlobalStyles } from './modules/styles';
import { Analytics } from './modules/analytics';
import { SAVE_MODE } from './constants';
import { Loader } from './components/Loader';
import { Error } from './components/Error';
import { Close } from './components/Close';
import { Tabs } from './components/Tabs';
import { Additional } from './components/Additional';
import { Render } from './components/Render';
import { Options } from './components/Options';
import { Confirm } from './components/Confirm';
import { Canvas } from './components/Render/Canvas';

const canvas = new Canvas();

export const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { tab, loading: { processing, error }, settings: { locale, additional }} = state;
  const [compare, setCompare] = useState(false);
  const [displayConfirm, setDisplayConfirm] = useState(false);

  const onSelectTab = (tab) => dispatch(effects.onSelectTab(tab));
  const onResetAll = () => dispatch(effects.onResetAll());
  const onCompare = (val) => setCompare(val);
  const onCloseConfirm = () => setDisplayConfirm(false);
  const onCloseWithoutSave = () => dispatch(effects.closeImageEditor());
  const onSaveCurrent = () => dispatch(effects.saveImage(SAVE_MODE.current));
  const onSaveNew = () => dispatch(effects.saveImage(SAVE_MODE.new));
  const onClose = () => canvas.isChanged ? setDisplayConfirm(true) : onCloseWithoutSave();

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());
      await dispatch(getData.getImageSize());
      dispatch(getData.getFilters());
    })();

    if (!StatusInterfaces.checkAnyOneOpen(['cabinet'])) {
      notifications.init('image-editor-notifications');
    }

    const { project_name, countRender, imageId, projectId } = additional;

    Analytics.openImageEditor(locale, projectId, project_name, imageId, countRender);
  }, []);

  return (
    <>
      <GlobalStyles />
      <Container>
        <Wrapper>
          <Inner>
            {error && <Editor><Error error={error} /></Editor>}
            {(processing && !error) && <Editor><Loader /></Editor>}
            {(!processing && !error) && (
              <Editor>
                <Content>
                  <Render compare={compare} />
                  <Sidebar>
                    <Tabs currentTab={tab} onSelectTab={onSelectTab} />
                    <Options tab={tab} />
                    <Additional onResetAll={onResetAll} onCompare={onCompare} />
                  </Sidebar>
                </Content>

                {displayConfirm && (
                  <Confirm
                    onClose={onCloseConfirm}
                    onCloseWithoutSave={onCloseWithoutSave}
                    onSaveCurrent={onSaveCurrent}
                    onSaveNew={onSaveNew} />
                )}
              </Editor>
            )}

            <Controls>
              <Close onClose={onClose} />
            </Controls>
          </Inner>
        </Wrapper>
        <div id="image-editor-notifications" />
      </Container>
    </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  background-color: ${rgba(Colors.black, 0.4)};
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 60px 80px;
  min-height: 650px;
`;
const Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const Editor = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;
const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
`;
const Header = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.coal};
`;
const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  min-height: 0;
`;
const Sidebar = styled.div`
  width: 260px;
  border-left: 1px solid ${Colors.coal};
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const Additionals2 = styled.div``;
