import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { actions, url } from '@globalInvoke';
import { CHANGE_TEAM, URL_OPEN } from '@globalConstants';
import { MODALS } from '@features/unity/constants';
import { notifications } from '@features/notifications';
import { translations, isLocalHost, isBeta, DisplayInfoBanner } from '@libs';
import * as action from '../../../modules/Store/action';
import {
  ExpiredTeamModal,
  BlockedTeamModal,
  useModals
} from '@components';
import { PAGES, GET_GOODS, TEAM_CHANGED, SET_TEAM_LOADING, TEAM_ROLES } from '../../../constants';
import { IconChevron } from '../../icons';
import { compareVersions } from '@features/unity/layout/modules/helper';

export const Teams = () => {
  const dispatch = useDispatch();
  const [modals, openModal, closeAllModals] = useModals([MODALS.EXPIRED_TEAM, MODALS.BLOCKED_TEAM]);
  const { profile, team, loading, settings: { locale, version } } = useSelector(state => state);
  const [open, setOpen] = useState(false);
  const listRef = useRef();
  const currentRef = useRef();
  const defLogoTeam = 'https://uikit.planoplan.com/static/images/icons/ava-team.png';
  const teams = team.list;
  const loadingGoods = loading.getters[GET_GOODS] || {};
  const name = team.current ? team?.current?.caption : profile.name;
  const logo = team.current ? team?.current?.logo : profile.avatar;

  const runChange = (id) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SET_TEAM_LOADING, payload: true });

      window.invokeEditorAction({
        name: actions[CHANGE_TEAM].name,
        value: id ? id : -1,
      });

      window.invokeAPI.registerDispatch('CABINET', {
        [TEAM_CHANGED]: (teamId) => {
          const idTeam = teamId === -1 ? 0 : teamId;

          idTeam === id ? resolve() : reject();
        },
      });
    });
  };
  
  const checkIsAvailableTeam = (id) => {
    if (id === 0) return true;

    try {
      const currentTeam = team.list.find(team => id === team.id);

      return currentTeam.role !== TEAM_ROLES.blocked;
    } catch (error) {
      return true;
    }
  }

  const setTeam = async (id) => {
    setOpen(false);

    if (loadingGoods.processing) {
      return;
    }

    if (!checkIsAvailableTeam(id)) {
      openModal(MODALS.BLOCKED_TEAM, { teamId: id });

      return;
    }

    if (isLocalHost) {
      setTimeout(() => {
        window.UIInvokeFunction('TeamChanged', id);
      }, 2000);
    }

    runChange(id)
      .then(() => {
        dispatch(action.setTeamId(id));
        dispatch(action.setPage(PAGES.waiting));
        dispatch({ type: SET_TEAM_LOADING, payload: false });

        // Проверяем на вывод информационных баннеров
        DisplayInfoBanner.init({ locale });
      })
      .catch(() => {
        notifications.showError(translations.t('cabinet.notification.team.error'));
        dispatch({ type: SET_TEAM_LOADING, payload: false });
      });
  };

  const onWriteEmail = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: 'mailto:corporate@planoplan.com',
    });

    closeAllModals();
  };

  const onClickOutside = (event) => {
    if (!listRef?.current?.contains(event.target) && !currentRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (team.expired) {
      openModal(MODALS.EXPIRED_TEAM);
    }
  }, [team.expired]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside);

    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  return (
    <View>
      <Current ref={currentRef} isClickable={Boolean(teams.length)} onClick={() => setOpen(!open)}>
        <Image>
          <img src={logo || defLogoTeam} />
        </Image>
        
        <Name>{name}</Name>

        {Boolean(teams.length) && <Down isOpen={open}><IconChevron /></Down>}
      </Current>

      {open && (
        <List ref={listRef}>
          <ListItem key={0} isActive={team.id === 0} onClick={() => setTeam(0)}>{profile.name}</ListItem>

          {Boolean(teams && teams.length) && teams.map((item) => (
            <ListItem key={item.id} isActive={item.id === team.id} onClick={() => setTeam(item.id)}>{item.caption}</ListItem>
          ))}
        </List>
      )}

      <ExpiredTeamModal isOpen={modals[MODALS.EXPIRED_TEAM].isOpen}
                        onCancel={closeAllModals}
                        onWriteEmail={onWriteEmail}/>
                        
      <BlockedTeamModal isOpen={modals[MODALS.BLOCKED_TEAM].isOpen} params={modals[MODALS.BLOCKED_TEAM].params} onCancel={closeAllModals}/>
    </View>
  )
};

const View = styled.div`
  margin-bottom: 16px;
  position: relative;
`;
const Down = styled.div`
  ${Mixins.transition('background-color')};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 5px;

  svg {
    ${Mixins.transition('transform')};
    transform: ${({ isOpen }) => isOpen ? 'rotate(270deg)' : 'rotate(90deg)'};
  }
`;
const Current = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};

  &:hover {
    ${Down} {
      background-color: ${Colors.main_beige};
    }
  }
`;
const Image = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  background-color: #D9D9D9;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Name = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const List = styled.div`
  position: absolute;
  top: 100%;
  right: -15px;
  margin-top: 3px;
  background-color: ${Colors.white};
  box-shadow: 1px 2px 4px 0px #2929290A,
              3px 7px 8px 0px #29292908,
              8px 16px 11px 0px #29292905,
              14px 29px 13px 0px #29292903,
              21px 45px 14px 0px #29292900;
  padding: 10px 0;
  z-index: 1;
  border-radius: 5px;
  min-width: 100px;
  max-width: 100%;
`;
const ListItem = styled.div`
  padding: 5px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isActive }) => isActive ? Colors.white : Colors.main_dark};
  background-color: ${({ isActive }) => isActive ? Colors.main_blue : Colors.white};

  &:hover {
    color: ${Colors.white};
    background-color: ${Colors.main_blue};
  }
`;
