import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IArrowNext } from 'planoplan-ui-kit/core/icons/i-arrow-next';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const RenderTooltip = ({ items = [] }) => {
  const [hover, setHover] = useState(false);
  const [position, setPosition] = useState('bottom');
  const [visible, setVisible] = useState(false);
  const arrowRef = useRef(null);
  const contentRef = useRef(null);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  useEffect(() => {
    if (hover && contentRef.current && arrowRef.current) {
      const size = contentRef.current.getBoundingClientRect();
      const margin = 15; // 5px отступ тултипа и 10px отступ от края окна кабинета
      const bottomCoords = size.bottom + margin;
      const topCoords = size.top - size.height - arrowRef.current.offsetHeight - margin;

      if (bottomCoords > window.innerHeight && topCoords >= 0) {
        setPosition('top');
      }

      setVisible(true);
    } else {
      setVisible(false);
      setPosition('bottom');
    }

  }, [hover]);

  return (
    <Tooltip>
      <TooltipArrow onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} disabled={!items?.length} ref={arrowRef}>
        <IArrowNext width="14" height="14" fill={Colors.main_dark} />
      </TooltipArrow>

      {hover && (
        <TooltipContent ref={contentRef} position={position} visible={visible}>
          {items && items.map((period) => (
            <RenderPeriod key={period.date}>
              <RenderPeriodDate>{period.date}</RenderPeriodDate>

              {period.items.map((item, index) => (
                <RenderPeriodItem key={index}>
                  <RenderPeriodCaption>{item.caption}</RenderPeriodCaption>
                  <RenderPeriodCount>{item.count}</RenderPeriodCount>
                </RenderPeriodItem>
              ))}
            </RenderPeriod>
          ))}
        </TooltipContent>
      )}
    </Tooltip>
  );
};

const TooltipContent = styled.div`
  ${Mixins.transition('opacity')};
  position:absolute;
  top: ${({ position }) => position === 'bottom' ? '100%' : 'auto'};
  bottom: ${({ position }) => position === 'top' ? '100%' : 'auto'};
  right: -5px;
  width: 150px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${Colors.white};
  border-radius: 4px;
  flex-direction: column;
  text-align: left;
  z-index: 1;
  font-size: 14px;
  box-shadow: 14px 29px 13px rgba(242, 158, 158, 0.01), 8px 16px 11px rgba(41, 41, 41, 0.02), 3px 7px 8px rgba(41, 41, 41, 0.03), 1px 2px 4px rgba(41, 41, 41, 0.04);
  opacity: ${({ visible }) => visible ? 1 : 0};
`;
const TooltipArrow = styled.div`
  ${Mixins.transition('background-color')};
  transform: rotate(90deg);
  cursor: pointer;
  margin-left: 5px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: ${({ disabled }) => disabled ? 0.3 : 1};
  border: 1px solid;
`;
const Tooltip = styled.div`
  position: relative;
  
  &:hover {
      ${TooltipArrow} {
        background-color: ${Colors.main_beige};
      }
  }
`;
const RenderPeriod = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.light_gray};
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
`;
const RenderPeriodDate = styled.div`
  border-bottom: 1px solid ${Colors.light_gray};
  text-align: right;
  color: ${Colors.light_gray};
  padding: 10px 20px 2px 5px;
`;
const RenderPeriodItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 10px;
`;
const RenderPeriodCaption = styled.div`
  margin-right: 10px;
`;

const RenderPeriodCount = styled.div`
  margin-right: 10px;
`;