import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';
import { TEAM_ROLES } from '@features/cabinet/constants';

export const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

export const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};

export const PermissionsTeamFolderModal = ({ isOpen, folderId = 0, onCancel = () => {}, onTakePermissions = () => {} }) => {
  const { team, projects, settings: { zoom }} = useSelector(state => state);
  const folder = useMemo(() => projects.items.find(item => item.row_type === 'folder' && item.id === folderId), [folderId]);
  const options = useMemo(() => {
    return team.members
      .filter((member) => member.role !== TEAM_ROLES.owner)
      .map((member) => ({
        value: member.id,
        render: <Option><Avatar src={member.avatar} /><Name>{member.name || member.email || member.id}</Name></Option>,
        disabled: false,
      }))
  }, [team.members]);
  const [selected, setSelected] = useState([]);

  const onSelected = (option) => {
    if (option.disabled) {
      return;
    }

    if (selected.includes(option.value)) {
      setSelected(selected.filter(value => option.value !== value));
    } else {
      setSelected([...selected, option.value]);
    }
  };

  useEffect(() => {
    setSelected(folder?.permissions?.edit || []);
  }, [folder]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.permissions.title')}</Header>

      <Content>
        <CustomSelect zoom={zoom}>
          <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
            {options.map((option) => (
              <ItemSelect key={option.value} disabled={option.disabled} selected={selected.includes(option.value)} onClick={() => onSelected(option)}>
                {option.render}
              </ItemSelect>
            ))}
          </ScrollBar>
        </CustomSelect>
      </Content>

      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={() => onTakePermissions(selected)}>
          {translations.t('modal.apply')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${Colors.second_gray};
  margin-right: 16px;
  object-fit: cover;
  flex-shrink: 0;
`;
const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CustomSelect = styled.div`
  border: 1px solid ${Colors.main_beige};
  border-radius: 4px;
  max-height: ${({ zoom }) => 50 / zoom}vh;
  overflow: auto;
  width: 100%;
  background-color: #f0f0f0;
`;
const ItemSelect = styled.div`
  ${Mixins.transition('color, background-color')};
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  color: ${({ disabled, selected }) => disabled ? Colors.second_light_gray : selected ? Colors.white : Colors.main_dark};
  background-color: ${({ selected }) => selected ? Colors.main_blue : 'transparent'};

  ${({ disabled }) => !disabled && `
    &:hover {
      color: ${Colors.white};
      background-color: ${Colors.main_blue};
    }
  `}
`;