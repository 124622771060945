import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from './Table';
import { translations, formatDate } from '@libs';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { MODALS } from '@features/unity/constants';
import * as effects from '../effects';
import { TableActions } from './TableActions';
import * as getData from '../../../modules/getdata';
import { GET_TEAM_MEMBERS } from '../../../constants';
import {
  AddUserInTeamModal,
  RemoveUserFromTeamModal,
  useModals
} from '@components';
import { Close } from '../../../components/Close';
import { IconAdd } from '../../../components/icons/add';
import { RenderTooltip } from './render-tooltip';

export const TableContainer = ({ members }) => {
  const dispatch = useDispatch();
  const { team: { current }, loading } = useSelector(state => state);
  const isLoadingMember = loading?.getters?.[GET_TEAM_MEMBERS]?.processing;

  const columns = [
    {
      header: 'ID',
      accessor: 'id',
      style: {
        width: '10%',
        alignItems: 'start',
      },
      sortable: true,
      align: 'left',
      render: ({ id }) => <InnerCol><ColBold>{id}</ColBold></InnerCol>,
    },
    {
      header: translations.t('cabinet.login'),
      accessor: 'email',
      style: {
        width: '25%',
      },
      sortable: true,
      align: 'left',
      render: ({ email }) => <InnerCol>{email}</InnerCol>,
    },
    {
      header: translations.t('cabinet.name'),
      accessor: 'name',
      style: {
        width: '15%',
      },
      sortable: true,
      align: 'left',
      render: ({ name }) => (<InnerCol>{name}</InnerCol>),
    },
    {
      header: translations.t('cabinet.renders'),
      accessor: 'render_stat.total',
      sortable: true,
      align: 'right',
      style: {
        width: '10%',
      },
      render: ({ render_stat }) => (
        <InnerColBetween>
          {render_stat.total}
          <RenderTooltip items={render_stat.items} />
        </InnerColBetween>
      ),
    },
    {
      header: translations.t('cabinet.members.last_activity'),
      accessor: 'last_activity',
      sortable: true,
      align: 'right',
      style: {
        width: '18%',
      },
      render: ({ last_activity }) => (
        <InnerCol>{formatDate(last_activity)}</InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.projects.plural.5'),
      accessor: 'projects_count',
      sortable: true,
      align: 'right',
      style: {
        width: '10%',
      },
      render: ({ projects_count }) => (
        <InnerCol>{projects_count}</InnerCol>
      ),
    },
    {
      header: '',
      sortable: false,
      align: 'right',
      style: {
        width: '12%',
      },
      render: (member) => (<TableActions member={member} onRemoveUser={(id) => openModal(MODALS.REMOVE_FROM_TEAM, id)} />),
    },
  ];

  const [modals, openModal, closeAllModals] = useModals([MODALS.ADD_USER_IN_TEAM, MODALS.REMOVE_FROM_TEAM]);
  const openModalAddUser = () => openModal(MODALS.ADD_USER_IN_TEAM);
  const onAddUser = async (email) => {
    const status = await dispatch(effects.addUserInTeam(email));

    if (status) {
      closeAllModals();
    }
  };

  const onRemoveUser = async () => {
    const id = modals[MODALS.REMOVE_FROM_TEAM].params;

    closeAllModals();
    await dispatch(effects.removeUserFromTeam(id));
  };

  return (
    <View>
      <Top>
        <TopLeft>
          <Title>{translations.t('cabinet.members.title_page')}</Title>
          <ButtonInvoke theme="feldgrau" size="M" widthText={true} onClick={openModalAddUser}>
            {translations.t('cabinet.members.add.user')}
            <IconAdd fill={Colors.main_dark} />
          </ButtonInvoke>
        </TopLeft>

        <Close />
      </Top>

      <Container>
        {isLoadingMember && (
          <Loader>
            <Preloader width="80px" height="80px" fill={Colors.main_blue} />
          </Loader>
        )}

        {Boolean(members.length && !isLoadingMember) && <Table data={members} columns={columns} isSort={false} />}

        <AddUserInTeamModal isOpen={modals[MODALS.ADD_USER_IN_TEAM].isOpen}
                            onCancel={closeAllModals}
                            onAddUser={(params) => onAddUser(params)}/>

        <RemoveUserFromTeamModal isOpen={modals[MODALS.REMOVE_FROM_TEAM].isOpen}
                                onCancel={closeAllModals}
                                onRemoveUser={onRemoveUser}/>
      </Container>
    </View>
  );
};

const View = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 60px;
`;
const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const TopLeft = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-left: 6px;
  margin-right: 10px;
`;
const InnerCol = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InnerColBetween = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Col = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;
const ColBold = styled(Col)`
  font-weight: 400;
`;
const ButtonInvoke = styled(Button)`
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: normal;
  padding: 0 20px;
  border-color: ${Colors.main_dark};
  color: ${Colors.main_dark};

  svg {
    margin-left: 5px;
  }

  &:hover {
    background-color: inherit;
    color: inherit;
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
    3px 4px 5px 0px ${Colors.main_dark}12,
    6px 9px 7px 0px ${Colors.main_dark}0A,
    11px 16px 8px 0px ${Colors.main_dark}03,
    17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    color: ${Colors.white};
    background-color: ${Colors.main_black};

    svg path {
      fill: ${Colors.white};
    }
  }
`;
