import { getErrorMessage } from '@globalErrorMessages';
import { POP_SCREEN_ENTRY_LOGIN, REGISTRATION_WITHOUT_TRIAL } from '@globalLocalStorage';
import { PROFILE_STATUS, ISDEVELOPMENT } from '@globalConstants';
import { getSubDomain, LocalStorage } from '@libs';
import { MODALS } from '@features/unity/constants';
import { CHECKING_MODE, ENTRY_STEPS, ERROR_TYPES, SIGN_UP_MODE, AUTH_ERROR_MESSAGES, OPEN_AUTH_MODAL } from '../constants';
import { ApiCall } from './api-call';
import { analytics } from './analytics';
import {
  setStep,
  tokensReceived,
  successLogin,
  setCanResendCode,
  moveOnCheckingEmail,
  moveOnSurveyScreen,
  getUserSettings
} from './effects';
import { vkCall } from './api-social';
import * as actions from './Store';
import { setSocialInfo } from './Store';

const observer = new window.POPObserver();

// Регистрация по почте
export const emailSignUp = (username, password, executeRecaptcha, openModal = () => {}, reqParams = {}) => async (dispatch, getState) => {
  const { settings: { mode, unity_clientID }} = getState();

  dispatch(actions.startProcessing());

  LocalStorage.set(POP_SCREEN_ENTRY_LOGIN, username);

  try {
    const g_recaptcha_response = await executeRecaptcha('signup');
    const params = { email: username, password, g_recaptcha_response, agreements: true, mode, fingerprint: unity_clientID, ...reqParams };
    const response = await ApiCall.setSignUp({ params });
    const { data } = response;
    const { success, errorText } = data;

    if (!success && errorText) {
      if (errorText === AUTH_ERROR_MESSAGES.deletedAccountDetected) {
        openModal(MODALS.CONFIRM_RECOVERY_ACCOUNT, { 
          email: params.email,
          deletedByEmail: true,
          deleted: true,
          cbContinue: () => dispatch(emailSignUp(username, password, executeRecaptcha, openModal, { newAccountNeeded: 1 })),
        });
      }

      if (errorText === AUTH_ERROR_MESSAGES.duplicateRegistrationDetected) {
        openModal(MODALS.CONFIRM_RECOVERY_ACCOUNT, { 
          email: data.data.email,
          deleted: data.data.deleted,
          cbContinue: () => dispatch(emailSignUp(username, password, executeRecaptcha, openModal, { newAccountNeeded: 1 })),
        });
      }

      return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage(errorText) }));
    }

    if (!success) {
      throw new Error('EmailSignUp in not success');
    }

    const subDomain = getSubDomain();
    const domain = subDomain ? subDomain : 'planoplan';
    const body = { username, password };

    const responseAuth = await ApiCall.getAuth({ body, domain });
    const { data: dataAuth } = responseAuth;

    dispatch(tokensReceived({ ...dataAuth, domain }));
    dispatch(actions.setCheckingEmail(username));
    dispatch(actions.setCheckingMode(CHECKING_MODE.CONFIRM_EMAIL_AFTER_SIGNUP));
    dispatch(actions.setFirstLogin(true));
    dispatch(actions.setIsLogin(false));
    dispatch(setCanResendCode(false));
    dispatch(getUserSettings());

    if (reqParams?.newAccountNeeded) {
      LocalStorage.set(REGISTRATION_WITHOUT_TRIAL, 1);
    }
    
    analytics.pushEvent('sign-up', 'sent', 'email', dataAuth.id);

    dispatch(setStep(ENTRY_STEPS.CHECK));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.SIGNUP, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }

};

// Завершение регистрации через соцсети
export const socialSignUp = (code = '', mode = '', profile = {}, reqParams = {}) => async (dispatch, getState) => {
  const { signUpMode, settings } = getState();
  const social = settings.social || {};
  const subDomain = getSubDomain();
  const domain = subDomain ? subDomain : 'planoplan';
  // Данные передоваемые в шаг вывода ошибки, Для передачи email, social
  const errorData = {};

  try {
    const params = { code, mode, ...profile, ...reqParams, fingerprint: settings.unity_clientID };
    const responseSocialCode = await ApiCall.getSocialCode({ params });
    const respData = responseSocialCode.data;

    if (respData.error) {
      errorData.email = respData && respData.email ? respData.email : '';
      errorData.social = mode;

      if (respData.error.message === AUTH_ERROR_MESSAGES.deletedAccountDetected) {        
        observer.postMessage(OPEN_AUTH_MODAL, {
          alias: MODALS.CONFIRM_RECOVERY_ACCOUNT,
          params: {
            email: '',
            deleted: true,
            cbContinue: () => dispatch(socialSignUp(code, mode, profile, { newAccountNeeded: 1 })),
          },
        });

        return;
      }

      if (respData.error.message === AUTH_ERROR_MESSAGES.duplicateRegistrationDetected) {
        observer.postMessage(OPEN_AUTH_MODAL, {
          alias: MODALS.CONFIRM_RECOVERY_ACCOUNT,
          params: {
            email: '',
            deleted: respData.data.deleted,
            cbContinue: () => dispatch(socialSignUp(code, mode, profile, { newAccountNeeded: 1 })),
          },
        });

        return;
      }

      throw new Error(respData.error.message);
    }

    const socialCode = responseSocialCode.data.code;
    const isLogin = responseSocialCode.data.login;

    const response = await ApiCall.getSocialAuth({ code: socialCode, domain });
    const { data } = response;

    const responseUser = await ApiCall.getUserProfile();
    const { data: userData } = responseUser;

    if (!data || !userData) {
      throw new Error('SocialLogin is not have data');
    }

    const { id, status, name, avatar, email, surveyed } = userData;

    dispatch(actions.setIsLogin(isLogin));
    dispatch(actions.setProfile(userData));
    dispatch(actions.setSurveyCompleted(surveyed));
    dispatch(getUserSettings());
    dispatch(actions.setFirstLogin(true));
    dispatch(tokensReceived({ ...data, domain }));
    LocalStorage.set(REGISTRATION_WITHOUT_TRIAL, email);

    if (reqParams?.newAccountNeeded) {
      LocalStorage.set(REGISTRATION_WITHOUT_TRIAL, 1);
    }

    analytics.push({
      event: 'gaEvent',
      gaCategory: isLogin ? 'login' : 'sign-up',
      gaAction: 'sent',
      gaLabel: signUpMode,
      clientID_web: `${social.gaId}`,
    }, userData);

    if (!email) {
      dispatch(setSocialInfo(name, avatar));
      dispatch(setStep(ENTRY_STEPS.SOCIAL));
      return;
    }

    if (email && status === PROFILE_STATUS.NOT_CONFIRM) {
      dispatch(moveOnCheckingEmail({ id, email }));
      return;
    }

    if (((email && status === PROFILE_STATUS.CONFIRM) || !Boolean(surveyed)) && !isLogin) {
      analytics.pushEvent('sign-up-conf', 'sent', signUpMode);
    }

    if (!Boolean(surveyed)) {
      dispatch(moveOnSurveyScreen(surveyed));
      return;
    }

    if (email && status === PROFILE_STATUS.CONFIRM) {
      dispatch(actions.setLoginMode(signUpMode));
      dispatch(successLogin());
    }
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.SIGNUP, error, errorData }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  }
};

// Регистрация через VK
export const vkSignUp = ({ vkCode = '', mode = 'vkontakte' } = {}) => async (dispatch) => {
  dispatch(actions.startProcessing());
  dispatch(actions.setSignUpMode(SIGN_UP_MODE.VK));

  try {
    let code = vkCode;

    if (!code) {
      if (ISDEVELOPMENT) {
        code = 123;
      } else {
        const auth = await vkCall();

        code = auth.code;
      }
    }

    await dispatch(socialSignUp(code, mode));
  } catch (error) {
    dispatch(actions.setSignUpMode(SIGN_UP_MODE.EMAIL));

    if (error !== 'popup_closed_by_user') {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.SIGNUP, error }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Регистрация через Goggle
export const googleSignUp = ({ code = '', profile } = {}) => async (dispatch) => {
  dispatch(actions.startProcessing());
  dispatch(actions.setSignUpMode(SIGN_UP_MODE.GOOGLE));

  try {
    await dispatch(socialSignUp(code, 'google', profile));
  } catch (error) {
    dispatch(actions.setSignUpMode(SIGN_UP_MODE.EMAIL));

    if (error.error !== 'popup_closed_by_user') {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.SIGNUP, error }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Подтвержение входа через соцсети
export const submitSocialSignUp = (email) => async (dispatch) => {
  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.setEmail({ email });

    const { data } = response;
    const { success, errorText, errors } = data;

    if (!success && errorText === 'This email address already exists') {
      return dispatch(
        actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage('This email is already in use') })
      );
    }

    if (!success && errorText && errors && errors[0]['id'] === 'item[user.email]') {
      return dispatch(
        actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage('This email is already in use') })
      );
    }

    if (!success) {
      throw new Error(errorText);
    }

    dispatch(actions.setCheckingEmail(email));
    dispatch(setCanResendCode(false));
    dispatch(setStep(ENTRY_STEPS.CHECK));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.SOCIAL, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};
