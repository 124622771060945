import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.GALLERY_CLOSE]: {
    name: 'GalleryClose',
    value: true,
    type: 'string',
    alias: 'Close_Gallery',
  },
  [BUTTONS.DOWNLOAD]: {
    name: 'GalleryDownloadImages',
    value: [],
    type: 'array',
    alias: 'Gallery_Download_Images',
  },
  [BUTTONS.CREATE_RENDER]: {
    name: 'GalleryCreateRender',
    value: {},
    type: 'object',
    alias: 'Gallery_Create_Render',
  },
};
