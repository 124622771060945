import { TEAM_ROLES } from '../../constants';

// Только для проектов. Ищет ${} из name и заменяет на параметр project. ID ${id} --> ID 1234
export const getCorrectEditList = (editList, project) => {
  return editList.map((item) => {
    const paths = item.name.match(/\$\{[a-z]+\}/);

    if (!paths) {
      return { ...item };
    }

    const key = paths[0].slice(2, -1);
    const value = project[key];

    return {
      ...item,
      name: item.name.replace(/\$\{[a-z]+\}/, value),
    }
  });
};

export const checkPermissionsFolder = (folder = null, options = {}) => (dispatch, getState) => {
  const { onlyAdmin = false } = options;
  const { team, profile, projects: { currentFolder }} = getState();
  const folderWithPermissions = folder || currentFolder;

  // Если проверяем права не в команде то даем права редактирования
  if (!team.id) return true;

  // Если пользователь в команде админ или владелец то даем права
  if ([TEAM_ROLES.owner, TEAM_ROLES.admin].includes(team?.current?.role)) return true;

  // Если проверяем доступ только для админов то дальше проверку делать не надо
  if (onlyAdmin) {
    return false;
  }

  // Если текущей папки нету, т.е. верхний уровень, то даем права 
  if (!folderWithPermissions) return true;

  const permissions = folderWithPermissions?.permissions?.edit || [];

  // Если в папке не указаны права для пользователей, т.е. массив пустой, то дает права 
  if (!permissions || !permissions.length) return true;

  return permissions.includes(profile.id);
};
