import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import App from './app';
import { createAppStore, initialState } from './modules/Store';
import { ApiCall } from './modules/api-call';
import { LocalStorage, StatusInterfaces } from '@libs';
import { LOCAL_STORE_ASSISTANT_DATA, DEFAULT_ARTICLE_ALIAS } from './constants';

export default class UIAssistant {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings = {}) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения магазина не указан html элемент');
    this.locale = settings.locale;
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    const saveParams = LocalStorage.get(LOCAL_STORE_ASSISTANT_DATA) || {};
    const { closed } = saveParams;

    if (closed && !params.run) {
      return;
    }

    const alias = params.alias || saveParams.alias || DEFAULT_ARTICLE_ALIAS;

    const paramsData = {
      ...saveParams,
      ...params,
      closed: false,
      alias: alias,
    };

    clearTimeout(window.interfaceAssistantTimerClose);

    if (params.alias !== saveParams.alias || saveParams.closed) {
      this._unMount();
      this._mount(paramsData);

      ApiCall.setSettings({ locale: this.locale });
      this._setAnalytics(paramsData);
      LocalStorage.set(LOCAL_STORE_ASSISTANT_DATA, paramsData);
    }
  }

  /**
   * Устанавливает необходимые поля для аналитики.
   * @private
   */
  _setAnalytics(params) {
    if (!window.dataLayer) {
      return;
    }

    /*window.dataLayer.push({
      event: 'Pageview',
      pagePath: `/${this.locale}/projects/${project.id}/#popup-create-project`,
      pageTitle: `${project.name} - попап «Создание проекта» (standalone)`
    });*/
  }

  close(timeClose = 0) {
    const saveParams = LocalStorage.get(LOCAL_STORE_ASSISTANT_DATA);

    LocalStorage.set(LOCAL_STORE_ASSISTANT_DATA, {
      ...saveParams,
      closed: true,
    });

    window.interfaceAssistantTimerClose = setTimeout(() => {
      this._unMount();
    }, timeClose);

    if (!StatusInterfaces.checkSomeoneOpen()) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount(params) {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState(params);
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState(params = {}) {
    return {
      ...initialState,
      alias: params.alias,
      hintOpen: params.hintOpen,
      settings: {
        root: this.root,
        locale: this.locale,
        close: this.close.bind(this)
      },
    };
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }
}

if (!window.UIAssistant) {
  window.UIAssistant = UIAssistant;
}
