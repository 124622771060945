import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getLocaleUi, ScrollLock, StatusInterfaces } from '@libs';
import { POINTER_OFF_INTERFACE, MODE_ALIAS, UNITY, POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { App } from './app';
import { createAppStore, initialState } from './modules/Store';
import { mappedButtons } from './modules/invoke';
import { BUTTONS, MODE_CLOSE } from './constants';
import { ApiCall } from './modules/api-call';
import { ON_MOTION_REQUEST } from '@observer/constants';
import * as effects from './modules/effects';
import * as getData from './modules/getdata';
import ErrorBoundary from './ErrorBoundary';

export default class UIGallery {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения галереи не указан html элемент');
    this.mode = settings.mode || 'site'; // unity, site
    this.platform = settings.platform || '';
    this.autoUpdateTimeout = '';
    this.isMounted = false;

    this._parseUrl();
    this._subscribes();
  }

  /**
   * Разбор урла, и открытие модуля если есть необходимые параметры
   * @private
   */
  _parseUrl() {
    const url = new URLSearchParams(window.location.search);
    const isGallery = url.has('gallery');
    const projectId = url.get('projectId');
    const openImageId = url.get('openImageId');

    if (isGallery) {
      this.open({
        projectId,
        openImageId,
      });
    }
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    const url = new URLSearchParams(window.location.search);

    this.locale = url.get('locale') || this.settings.locale || 'en';
    this.localeUi = getLocaleUi(this.locale);
    this.team_id = url.get('team_id') || params.team_id || '';
    this.projectId = params.projectId || ''; // id проекта, который будет открыт
    this.openImageId = params.openImageId || ''; // id элемента, который будет предвыбран
    this.imageUrl = params.imageUrl || ''; // открытие картинки по прямой ссылке
    this.previewUrl = params.previewUrl || ''; // урл для превью если открытие картинки по прямой ссылке
    this.panoramaUrl = params.panoramaUrl || ''; // урл для открытия панорамы по прямой ссылке
    this.tourUrl = params.tourUrl || ''; // урл для открытия тура по прямой ссылке
    this.useBodyScrollLock = params.useBodyScrollLock !== false;
    this.isPreviewMode = params.isPreviewMode || false; // превью мод (на новом сайте)
    this.sharedUser = params.sharedUser || null; // поделившийся пользователь - для  превью мода
    this.zoom = params.zoom || 1;

    ApiCall.setSettings({ locale: this.locale, team_id: this.team_id }); // ui доступен только для ru и en языках

    this._mount();
    this._editorInvoke();
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    this.store = store;
    this.isMounted = true;

    if (this.useBodyScrollLock) {
      ScrollLock.lock(rootElement);
    }

    setTimeout(() => {
      ReactDOM.render(
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>,
        rootElement
      );
      StatusInterfaces.openGallery();
    }, 100);
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      settings: {
        root: this.root,
        mode: this.mode,
        platform: this.platform,
        isPreviewMode: this.isPreviewMode,
        locale: this.locale,
        localeUi: this.localeUi, // язык для интерфейса из массива поддерживаемых языков
        projectId: `${this.projectId}`,
        openImageId: this.openImageId,
        imageUrl: this.imageUrl,
        previewUrl: this.previewUrl,
        panoramaUrl: this.panoramaUrl,
        tourUrl: this.tourUrl,
        sharedUser: this.sharedUser,
        team_id: this.team_id,
        close: this.close.bind(this),
        setAutoUpdateTimeout: this._setAutoUpdateTimeout,
        zoom: this.zoom,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    if (MODE_ALIAS[this.mode] === UNITY) {
      window.invokeEditorAction({
        name: interfaces[POINTER_OFF_INTERFACE].name,
        value: interfaces[POINTER_OFF_INTERFACE].value,
      });
    }
  }

  /**
   * Сохраняет таймаут автообнавления галереи
   * @param timeout
   * @private
   */
  _setAutoUpdateTimeout(timeout) {
    this.autoUpdateTimeout = timeout;
  }

  /**
   * Удаление модуля из дом-дерева, и триггер событий для юнити
   * @param mode {string} - режимы закрытия
   */
  close(mode = '') {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    StatusInterfaces.closeGallery();

    if (this.useBodyScrollLock) {
      ScrollLock.unlock(rootElement);
    }

    clearTimeout(this.autoUpdateTimeout);

    if (MODE_ALIAS[this.mode] === UNITY && mode !== MODE_CLOSE.WITH_OPEN_UI) {
      window.invokeEditorAction({
        name: mappedButtons[BUTTONS.GALLERY_CLOSE].name,
        value: mappedButtons[BUTTONS.GALLERY_CLOSE].value,
      });

      if (!StatusInterfaces.checkSomeoneOpen()) {
        window.invokeEditorAction({
          name: interfaces[POINTER_ON_INTERFACE].name,
          value: interfaces[POINTER_ON_INTERFACE].value,
        });
      }
    }
  }

  _motionRequest = false;

  _subscribes() {
    window.POPMotionObserver = new window.POPObserver();
    window.POPMotionObserver.addEventListener(ON_MOTION_REQUEST, () => {
      if(!this._motionRequest) {
        this._motionRequest = true;
        effects.deviceMotionRequest();
      }
    });
  }

  // Для сохраниея изображениея после пост-обработки
  saveImage(params) {
    if (!this.isMounted || !params.isGallery) return;

    // После закрытия редактора изображения отключается оверлей, Тут надо его запускать.
    if (MODE_ALIAS[this.mode] === UNITY) {
      window.invokeEditorAction({
        name: interfaces[POINTER_OFF_INTERFACE].name,
        value: interfaces[POINTER_OFF_INTERFACE].value,
      });
    }

    const { dispatch } = this.store;

    dispatch(effects.saveEditedImage(params));
  }

  updateGallery() {
    const { dispatch } = this.store;

    dispatch(getData.getGalleryImages());
  }
}

if (!window.UIGallery) {
  window.UIGallery = UIGallery;
}
