import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { POINTER_ON_INTERFACE, POINTER_OFF_INTERFACE, MODE_ALIAS, UNITY } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { StatusInterfaces } from '@libs';
import App from './app';
import { createAppStore, initialState } from './modules/Store';
import { ApiCall } from './modules/api-call';
import { mappedButtons } from './modules/invoke';
import { BUTTONS } from './constants';
import { Analytics } from './modules/analytics';

export default class UIProjects {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings = {}) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения магазина не указан html элемент');
    this.mode = settings.mode || 'site'; // unity, site
    this.locale = settings.locale;
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    this._mount();
    this._editorInvoke();

    ApiCall.setSettings({ locale: this.locale });
    Analytics.setSettings({ project: params.project });
    Analytics.viewProjects(this.locale);
  }

  close() {
    this._unMount();

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.PROJECTS_CLOSE].name,
      value: mappedButtons[BUTTONS.PROJECTS_CLOSE].value,
    });

    if (!StatusInterfaces.checkSomeoneOpen()) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
    StatusInterfaces.openProjects();
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      settings: {
        root: this.root,
        locale: this.locale,
        mode: this.mode,
        close: this.close.bind(this)
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    if (MODE_ALIAS[this.mode] === UNITY) {
      window.invokeEditorAction({
        name: interfaces[POINTER_OFF_INTERFACE].name,
        value: interfaces[POINTER_OFF_INTERFACE].value,
      });
    }
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    StatusInterfaces.closeProjects();
  }
}

if (!window.UIProjects) {
  window.UIProjects = UIProjects;
}
