export const INTERFACE_INIT = 'INTERFACE_INIT';
export const LAYOUT_INIT = 'LAYOUT_INIT';
export const APP_CLOSE = 'APP_CLOSE';
export const TOKENS_UPDATE = 'TOKENS_UPDATE';
export const SET_ZOOM = 'SET_ZOOM';
export const PROJECT_WAS_OPENED = 'PROJECT_WAS_OPENED';
export const SHORTAGE_TEAM_LICENSE = 'SHORTAGE_TEAM_LICENSE';
export const LICENSE_UPDATE = 'LICENSE_UPDATE';
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

export const MODALS = {
  CLOSE: 'CLOSE',
  DELETE: 'DELETE',
  DUPLICATE: 'DUPLICATE',
  MOVE: 'MOVE',
  MOVE_TO_TEAM: 'MOVE_TO_TEAM',
  CREATE_FOLDER: 'CREATE_FOLDER',
  SOCIAL_CONFIRM_GOOGLE: 'SOCIAL_CONFIRM_GOOGLE',
  SOCIAL_CONFIRM_VK: 'SOCIAL_CONFIRM_VK',
  SOCIAL_REMOVE_GOOGLE: 'SOCIAL_REMOVE_GOOGLE',
  SOCIAL_REMOVE_VK: 'SOCIAL_REMOVE_VK',
  SOCIAL_USED_VK: 'SOCIAL_USED_VK',
  SOCIAL_USED_GOOGLE: 'SOCIAL_USED_GOOGLE',
  ADD_USER_IN_TEAM: 'ADD_USER_IN_TEAM',
  EXPIRED_TEAM: 'EXPIRED_TEAM',
  BLOCKED_TEAM: 'BLOCKED_TEAM',
  REMOVE_FROM_TEAM: 'REMOVE_FROM_TEAM',
  LOGOUT_ANOTHER_DEVICE: 'LOGOUT_ANOTHER_DEVICE',
  SHORTAGE_TEAM_LICENSE: 'SHORTAGE_TEAM_LICENSE',
  UPLOAD_IMAGE_TO_DESIGN_PROJECT: 'UPLOAD_IMAGE_TO_DESIGN_PROJECT',
  GOOGLE_SHUTDOWN_NOTIFICATION: 'GOOGLE_SHUTDOWN_NOTIFICATION',
  TARIFF_BY_ON_REQUEST: 'TARIFF_BY_ON_REQUEST',
  CONFIRM_RECOVERY_ACCOUNT: 'CONFIRM_RECOVERY_ACCOUNT',
  PERMISSIONS_TEAM_FOLDER: 'PERMISSIONS_TEAM_FOLDER',
};

// Invoke
export const BUTTONS = {
  CLOSE_APP: 'CLOSE_APP',
};
