import React, { useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { notifications } from '@features/notifications';
import { translations, LocalStorage, StatusInterfaces } from '@libs';
import * as effects from './modules/effects';
import * as getData from './modules/getdata';
import { Analytics } from './modules/analytics';
import { GlobalStyles } from './modules/styles';
import { ON_OPEN_CABINET } from '@observer/constants';
import { POP_OPENED_ID_PROJECT } from '@globalLocalStorage';
import { SET_DESIGN_PROJECT_LOADING_PDF } from './constants';
import { Close } from './components/Close';
import { Loader } from './components/Loader';
import { Sidebar } from './components/Sidebar';
import { Content } from './components/Content';
import { TitlePage } from './components/TitlePage';
import { StructurePage } from './components/StructurePage';
import { ISetting, IconUpload } from './icons';
import { rail_y, thumb_y } from './components/scroll';

const observer = new window.POPObserver();

export const App = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { loading, profile, settings: { close, projectId, zoom }} = state;
  const { processing, error } = loading;
  const generation = loading.getters[SET_DESIGN_PROJECT_LOADING_PDF] || {};
  const showBody = generation.processing || !processing;

  const openCabinetProfile = () => {
    close();

    const idProject = LocalStorage.get(POP_OPENED_ID_PROJECT);

    observer.postMessage(ON_OPEN_CABINET, {
      page: 'profile',
      projectId: idProject
    });
  };

  const onClickExportPDF = () => {
    Analytics.exportPdf(projectId);
    dispatch(effects.exportPDF());
  };

  useEffect(() => {
    (async () => {
      dispatch(getData.getTranslations());
      await dispatch(getData.getProfile());
      await dispatch(getData.getGallery());

      dispatch(Analytics.openDesignProject());
    })();

    if (!StatusInterfaces.checkAnyOneOpen(['cabinet', 'gallery'])) {
      notifications.init('design-project-notifications');
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Container scaleFactor={1 / zoom}>
        <Wrapper>
          <InnerWrapper>
            <Controls>
              <Close />
            </Controls>

            <DesignProject>
              {processing && !error && <Loader />}

              {showBody && (
                <>
                  <Sidebar />

                  <Body>
                    <BodyScroll>
                      <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
                        <Top>
                          <TitlePage />
                          <StructurePage />
                        </Top>

                        <Content />
                      </ScrollBar>
                    </BodyScroll>

                    <Bottom>
                      {profile.logo && <Logo src={profile.logo} alt={'logo'} />}

                      <SettingLogo onClick={openCabinetProfile}>
                        {profile.logo ? <><ISetting fill={Colors.white} />{translations.t('gallery.pdf.logo.set')}</> : <><IconUpload width={20} height={20} fill={Colors.light_gray} />{translations.t('gallery.pdf.logo.upload')}</>}
                      </SettingLogo>

                      <Export onClick={onClickExportPDF}>{translations.t('gallery.pdf.export')}</Export>
                    </Bottom>
                  </Body>
                </>
              )}
            </DesignProject>
          </InnerWrapper>
        </Wrapper>

        <div id="design-project-notifications" />
      </Container>
    </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  background-color: ${rgba(Colors.black, 0.4)};
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 60px 80px;
  min-height: 650px;
  position: relative;
`;
const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: ${Colors.night};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
  border: 1px solid ${Colors.coal};
`;
const DesignProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${Colors.night};
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border: 1px solid ${Colors.coal};
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px 0 15px;
  overflow: hidden;
  flex-grow: 1;
`;
const BodyScroll = styled.div`
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
`;
const Top = styled.div`
  ${Grid.row()};
  margin-bottom: 20px;
  flex-shrink: 0;
  display: flex;
  padding-right: 20px;
  height: 216px; // Нужно для старого браузера стендалона, там блок StructurePage не хочет быть 100% высотой
`;
const Bottom = styled.div`
  padding: 9px;
  border-top: 1px solid ${Colors.coal};
  display: flex;
  justify-content: flex-end;  
  margin-left: -15px;
  margin-right: -5px;
  flex-shrink: 0;
`;
const Logo = styled.img`
  height: 24px;
  max-width: 300px;
  margin-right: 5px;
  border-radius: 4px;
`;
const SettingLogo = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color, color, border-color')};
  font-size: 12px;
  line-height: 100%;
  color: ${Colors.light_gray};
  height: 24px;
  border: 1px solid ${Colors.coal};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  margin-right: 10px;
  
  svg {
    margin-right: 6px;
  }
  
  &:hover {
    background-color: ${Colors.coal};
  }
`;
const Export = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color')};
  font-size: 12px;
  line-height: 100%;
  color: ${Colors.night};
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  background-color: ${Colors.dark_cream};
  
  &:hover {
    background-color: #FFB540;
  }
`;
