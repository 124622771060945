import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { translations, formatDate } from '@libs';
import { ON_LANGUAGE_LOAD_FOR_PAGE } from '@observer/constants';
import { rail_y, thumb_y } from '../../components/scroll';
import { Promo } from './organism/promo';
import { StoreItems } from './organism/store-items';
import { Analytics } from '../../modules/analytics';
import { Close } from '../../components/Close';

const observer = new window.POPObserver();

export const PageBalance = () => {
  const {
    balanceGoods: { renderGoods },
    tariff: { expire },
    team,
  } = useSelector((state) => state);
  const rendersWithBalance = renderGoods.filter(item => item.not_for_sale && item.available.balance);
  const additionalRenders = [...rendersWithBalance, ...renderGoods.filter(item => !item.not_for_sale)];
  const subscribeRenders = renderGoods.filter(item => item.available.subscription);

  useEffect(() => {
    Analytics.viewBalance();
    observer.addEventListener(ON_LANGUAGE_LOAD_FOR_PAGE, () => Analytics.viewBalance());

    return () => {
      observer.removeEventListener(ON_LANGUAGE_LOAD_FOR_PAGE);
    }
  }, []);

  if (team.expired) {
    return (
      <View>
        <Content>
          <TitleError>{translations.t('cabinet.team.expired.caption')}: {formatDate(expire)}</TitleError>
        </Content>
      </View>
    )
  }

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <Content>
          <Head>
            <Title>{translations.t('cabinet.balance.title')}</Title>
            <Close fixed />
          </Head>
          
          <PromoRow>
            <Promo />
          </PromoRow>

          <Store>
            <ItemContainer>
              <StoreItems title={translations.t('cabinet.balance.subscribe.title')} items={subscribeRenders} isSubscribe={true} />
            </ItemContainer>

            <ItemContainer>
              <StoreItems title={translations.t('cabinet.balance.additional.title')} items={additionalRenders} />
            </ItemContainer>
          </Store>
        </Content>
      </View>
    </ScrollBar>
  );
};

const View = styled.div``;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-left: 10px;
`;
const Content = styled.div`
  overflow: hidden;
  padding: 20px 0 20px 20px;
`;
const PromoRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 15px;
  width: 440px;
  height: 99px;
  border: 1px solid ${Colors.light_gray};
  margin-bottom: 10px;
  border-radius: 5px;
`;
const Store = styled.div`
 width: 440px;
`;
const ItemContainer = styled.div`
  border: 1px solid ${Colors.light_gray};
  border-radius: 5px;
  margin-bottom: 10px;
`;
const TitleError = styled.div`
  font-size: 44px;
  color: ${Colors.feldgrau25};
`;
