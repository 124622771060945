import React from 'react';
import ReactDOM from 'react-dom';
import { getLocaleUi, ScrollLock, StatusInterfaces } from '@libs';
import { POINTER_OFF_INTERFACE, MODE_ALIAS, UNITY } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import App from './app';
import { Store } from './modules/Store/index';
import { reducers } from './modules/Store/reducers';
import { initialState } from './modules/Store/state';
import { ApiCall } from './modules/api-call';
import { POP_SESSION } from '@globalCookie';
import { Cookie } from '@libs';
import { TARIFF_JOIN_GOODS, SCREEN_ALIAS } from './constants';
import ErrorBoundary from './ErrorBoundary';

export default class UIStore {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings = {}) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения магазина не указан html элемент');
    this.mode = settings.mode || 'single'; // single, unity, site
    this.user_id = settings.user_id || '';
    this.unity_clientid = settings.unity_clientid || '';

    this._parseUrl();
  }

  /**
   * Разбор урла, и открытие модуля если есть необходимые параметры
   * @private
   */
  _parseUrl() {
    // ?store=cloud&product=goods_3 || ?store&product=goods_3
    const url = new URLSearchParams(window.location.search);
    const isStore = url.has('store');
    let initialScreen = SCREEN_ALIAS[url.get('store')] ? url.get('store') : SCREEN_ALIAS.pricing.toLowerCase();
    const openProduct = url.get('product');
    const promocode = url.get('promocode');
    const tariff = url.get('tariff');
    if(!!openProduct || !!tariff) {
      initialScreen = 'cart';
    }

    const isUser = Cookie.getJSON(POP_SESSION);

    if (isStore && isUser) {
      this.open({
        cartTariff: tariff,
        initialCart: {
          [openProduct]: 1,
        },
        openProduct,
        promocode,
        initialScreen,
      });
    }
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    const url = new URLSearchParams(window.location.search);
    const isStoreTariff = params.cartTariff ? params.cartTariff : null;

    this.initialScreen = params.initialScreen || ''; // имя экрана, который будет открыт при инициализации магазина
    this.initialCart = params.initialCart || {}; // id продуктов и их количество для инициализации корзины
    this.onlyInitialCart = params.onlyInitialCart || false; // Выводим в корзине только продукты из initialCart
    this.openProduct = params.openProduct || ''; // id продукта, который необходимо открыть при инициализации магазина
    this.useBodyScrollLock = params.useBodyScrollLock !== false;
    this.team_id = url.get('team_id') || params.team_id || '';
    this.locale = url.get('locale') || this.settings.locale || 'en';
    this.orderid = url.get('orderid') || params.orderid || '';
    this.platform = url.get('platform') || '';
    this.zoom = params.zoom || 1;
    this.promocode = params.promocode || '';
    this.closeAfterPay = params.closeAfterPay || false;
    this.callbackAfterSuccessPay = params.callbackAfterSuccessPay || function () {};

    if (isStoreTariff && TARIFF_JOIN_GOODS[isStoreTariff] && !this.onlyInitialCart) {
      const goodsName = `goods_${TARIFF_JOIN_GOODS[isStoreTariff].year}`;
      const goodsIds = Object.values(TARIFF_JOIN_GOODS).reduce((acc, item) => acc.concat([`goods_${item.year}`, `goods_${item.month}`]), []);
      const initialCart = {};

      for (let storeId in this.initialCart) {
        if (Object.prototype.hasOwnProperty.call(this.initialCart, storeId)) {
          if (!goodsIds.includes(storeId)) {
            initialCart[storeId] = this.initialCart[storeId];
          }
        }
      }

      this.initialCart = {
        ...initialCart,
        [goodsName]: 1
      };
    }

    ApiCall.setSettings({ locale: this.locale, team_id: this.team_id });

    this._mount();
    this._editorInvoke();
    this._setAnalytics();
    this._setStylesForPayments(); // Стили для фреймов 3d-secure от stripe и Юкассы
  }

  /**
   * Удаление гет параметра по ключу
   * @param {string} key - ключ, который необходимо удалить
   * @private
   */
  _clearUrl(key) {
    const url = new URLSearchParams(window.location.search);

    if (Array.isArray(key)) {
      Array.from(key).forEach((i) => url.delete(i));
    } else {
      url.delete(key);
    }

    window.history.pushState(null, null, `?${url.toString()}`);
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);

    if (this.useBodyScrollLock) {
      ScrollLock.lock(rootElement);
    }

    setTimeout(() => {
      ReactDOM.render(
        <Store reducer={reducers} initialState={this._createInitialState()}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Store>,
        rootElement
      );
      StatusInterfaces.openStore();
    }, 100);
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      cart: [],
      settings: {
        root: this.root,
        locale: this.locale,
        user_id: this.user_id,
        team_id: this.team_id,
        unity_clientid: this.unity_clientid,
        mode: this.mode,
        localeUi: getLocaleUi(this.locale), // язык для интерфейса из массива поддерживаемых языков
        unMount: this._unMount.bind(this),
        clearUrl: this._clearUrl,
        initialCart: this.initialCart,
        onlyInitialCart: this.onlyInitialCart,
        initialScreen: this.initialScreen,
        openProduct: this.openProduct,
        hostname: window.location.host.indexOf('planoplan.com') !== -1 ? 'planoplan.com' : 'pp.ksdev.ru',
        orderid: this.orderid,
        zoom: this.zoom,
        promocode: this.promocode,
        closeAfterPay: this.closeAfterPay,
        callbackAfterSuccessPay: this.callbackAfterSuccessPay,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    if (MODE_ALIAS[this.mode] === UNITY) {
      window.invokeEditorAction({
        name: interfaces[POINTER_OFF_INTERFACE].name,
        value: interfaces[POINTER_OFF_INTERFACE].value,
      });
    }
  }

  /**
   * Устанавливает необходимые поля для аналитики. Вызывается только в случае редиректа с яндекс кассы из стендалона
   * @private
   */
  _setAnalytics() {
    if (this.orderid && this.platform === 'standalone') {
      window.dataLayer.push({
        Platform: 'standalone',
      });
    }
  }

  /**
   * Устанавливает стили для 3D-secure от страйпа и юкассы
   * @private
   */
  _setStylesForPayments() {
    if (!this.zoom || this.zoom === 1) {
      return;
    }

    const head = document.head;
    const zoom = this.zoom;
    const css = `
      .checkout-modal iframe {width: ${(100/zoom).toFixed(1)}% !important;transform: scale(${zoom});transform-origin: 0 0;}
      body > div > iframe {transform: scale(${zoom});transform-origin: 50% 40%;}
    `;
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    StatusInterfaces.closeStore();

    if (this.useBodyScrollLock) {
      ScrollLock.unlock(rootElement);
    }
  }
}

if (!window.UIStore) {
  window.UIStore = UIStore;
}
