import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { translations, SendLog, LocalStorage } from '@libs';
import { POP_PROFILE } from '@globalLocalStorage';
import { ApiCall } from './api-call';
import {
  getSecondGoodsTariff,
  getRenderQualityAlias,
  returnGoodsWithParams,
  getTariffPeriod,
  getPaymentMethodWithNewParams,
  getBankCardWithNewParams, getDefaultPackageCount
} from './helpers';
import * as actions from './Store/action';

import {
  GET_TRANSLATES,
  GET_BINDING_CARD,
  GET_PAYMENT_METHODS,
  GET_PROFILE,
  GET_GOODS,
  GET_COUNTRIES,
  GET_BANNERS,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PROFILE,
  SET_GOODS,
  SET_BINDING_CARD,
  SET_PAYMENT_METHODS,
  SET_COUNTRIES,
  SET_BANNER,
  GOODS_TARIFF_ALIAS,
  PANORAMA_ALIAS,
} from '../constants';

export const getTranslations = () => async (dispatch, state) => {
  const { settings } = state();
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    dispatch({ type: SET_LOADING_ON, payload: { name: GET_TRANSLATES } });

    try {
      await translations.load(settings.locale, [/^form_entry./, /^store./, /^cabinet./, /^quickstore./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^country./, /^site.notification/, /^projects./, /^assistant./]);

      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TRANSLATES } });
    } catch (error) {
      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TRANSLATES, error: error.message } });
    }
  }
};

export const getProfile = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROFILE } });

  try {
    const data = await ApiCall.getProfile();

    LocalStorage.set(POP_PROFILE, data);
    dispatch({
      type: SET_PROFILE,
      payload: {
        ...data,
        viewCurrency: getCurrency(data.currency),
      }
    });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE, error: error.message } });
  }
};

/*
* Находим один товар и если это тариф то находим его товарища месяц или год
* */
export const getGoods = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GOODS } });
  const { goodsId, tariffAlias, bannerAlias } = state();

  if (!goodsId && !tariffAlias) return;

  try {
    const { data } = await ApiCall.getGoods();
    const totalGoods = data.goods.map(item => returnGoodsWithParams(item));
    const goods = totalGoods.find((item) => {
      if (goodsId) {
        return Number(item.id) === Number(goodsId)
      }

      if (tariffAlias) {
        return item?.extra_data?.tariff_alias === tariffAlias;
      }
    });

    if (!goods) {
      SendLog.sendCrash({
        interface: 'quick-store',
        call_params: { goodsId, tariffAlias, bannerAlias },
        error: {
          message: `Goods not found!`,
        },
      });
      
      throw new Error('Goods not found!');
    }

    // Второй товар тарифа (месяц или год). если это рендер то вернет пустой объект
    const second = getSecondGoodsTariff(goods, totalGoods);

    if (!second) {
      throw new Error('Second tariff goods not found!');
    }

    // Добавляем алиас к рендеру, текущий и с каким сравниваем
    const qualityAliases = getRenderQualityAlias(goods);
    // Добавляет алиса периода к тарифу
    const tariffPeriod = getTariffPeriod(goods, second);

    const mainGoods = {
      ...goods,
      ...qualityAliases,
      ...tariffPeriod.main,
    };
    const secondGoods = {
      ...(second || {}),
      ...tariffPeriod.second,
    };
    const defaultPackageCount = getDefaultPackageCount(mainGoods);

    dispatch(actions.setPack(defaultPackageCount));
    dispatch({
      type: SET_GOODS,
      payload: {
        main: mainGoods,
        second: secondGoods,
        isTariff: GOODS_TARIFF_ALIAS.includes(goods.alias),
        isPanorama: goods.alias === PANORAMA_ALIAS,
      }
    });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS, error: error.message } });
  }
};

export const getBindingCard = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_BINDING_CARD } });

  try {
    const data = await ApiCall.getBindingCard();

    if (!data.success) {
      throw new Error('Server error!');
    }

    const bindingCards = getBankCardWithNewParams(data.data.items);

    /* Дефолтную карту ставим в начало */
    bindingCards.sort((a, b) => b.is_default - a.is_default);

    dispatch({ type: SET_BINDING_CARD, payload: bindingCards });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_BINDING_CARD, error: error.message },
    });
  }
};


export const getPaymentMethods = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PAYMENT_METHODS } });

  try {
    const data = await ApiCall.getPaymentMethods();

    if (!data.success) {
      throw new Error('Server error!');
    }

    const paymentMethods = getPaymentMethodWithNewParams(data.data);

    dispatch({ type: SET_PAYMENT_METHODS, payload: paymentMethods });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PAYMENT_METHODS } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PAYMENT_METHODS, error: error.message },
    });
  }
};

export const getCountries = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_COUNTRIES } });

  try {
    const data = await ApiCall.getCountries();

    if (!data.success) {
      throw new Error('Server error!');
    }

    const countries = data.data.countries.map((country) => ({
      ...country,
      caption: translations.t(`country.${country.value}`)
    }));

    dispatch({ type: SET_COUNTRIES, payload: countries });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_COUNTRIES } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_COUNTRIES, error: error.message },
    });
  }
};

export const getBanners = () => async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_BANNERS } });

  try {
    const { bannerAlias } = state();
    const data = await ApiCall.getBanners();
    const banner = data.find((banner) => banner.alias.trim() === bannerAlias.trim());

    if (banner) {
      dispatch({ type: SET_BANNER, payload: banner });
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BANNERS } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_BANNERS, error: error.message },
    });
  }
};


