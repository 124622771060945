import React from 'react';
import ReactDOM from 'react-dom';
import {
  POINTER_OFF_INTERFACE,
  POINTER_ON_INTERFACE,
  CLOSE_LOADER,
  CLOSE_LOADER_WEBGL_FN,
  CLOSE_LOADER_WEBGL_PARAMS,
  LOADER_READY,
} from '@globalConstants';
import { LOADER_IFRAME_ID } from './constants';
import { StatusInterfaces } from '@libs';
import { interfaces, actions } from '@globalInvoke';
import { App } from './app';

export default class UILoader {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings = {}) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения загрузчика не указан html элемент');
    this.locale = settings.locale || console.error('Для подключения загрузчика не указан язык');
    this.zoom = settings.zoom || 1;

    this.open();
    this.setZoom();
  }

  /**
   * Открытие модуля
   * @public
   */
  open() {
    this._mount();
    this._editorInvokeOpen();
  }

  /**
   * Закрытие модуля
   * @public
   */
  close() {
    this._unMount();
    this._editorInvokeClose();
  }

  /**
   * Webgl внутри фрейма готов
   * @public
   */
  ready() {
    this.setZoom();
    this._editorInvokeReady();
  }

  /**
   * Устанавливает масштаб внутри айфрейма
   */
  setZoom(zoom) {
    const frame = document.getElementById(LOADER_IFRAME_ID);
    const newZoom = typeof zoom === 'undefined' ? this.zoom : zoom;

    this.zoom = newZoom;

    if (frame) {
      frame.contentWindow.document.body.style.zoom = newZoom;
    }
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);

    setTimeout(() => {
      ReactDOM.render(
        <App locale={this.locale} onClose={() => this._closeFromWebgl()} />,
        rootElement
      );
    }, 100);
  }

  /**
   * Передает в юнити: заблокировать проход кликов через интерфейс
   * @private
   */
  _editorInvokeOpen() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  /**
   * Передает в юнити:
   * - загрузчик был закрыт из интерфейса
   * - разблокировать проход кликов через интерфейс
   * @private
   */
  _editorInvokeClose() {
    window.invokeEditorAction({
      name: actions[CLOSE_LOADER].name,
      value: actions[CLOSE_LOADER].value,
    });

    if (!StatusInterfaces.checkSomeoneOpen()) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }

  _editorInvokeReady() {
    window.invokeEditorAction({
      name: actions[LOADER_READY].name,
    });
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }

  /**
   * Закрытие модуля из webgl
   * @private
   */
  _closeFromWebgl() {
    window.UI2WebglInvokeFunction(CLOSE_LOADER_WEBGL_FN, CLOSE_LOADER_WEBGL_PARAMS);
  }
};

if (!window.UILoader) {
  window.UILoader = UILoader;
}
