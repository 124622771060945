import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { translations } from '@libs';
import { ButtonAdd } from './button-add';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ON_OPEN_STORE } from '@observer/constants';

export const Item = ({ item = {}, isSubscribe = false, }) => {
  const { id, caption, view_name, view_size, view_disabled, store_id, not_for_sale, available } = item;
  const observer = new window.POPObserver();
  const { team, tariff } = useSelector(state => state);
  const isInfinity = item.subscriptionQuantity === 0;
  const count = (isSubscribe ? item?.available?.subscription : item?.available?.balance) || 0;
  const total = item.subscriptionQuantity;
  
  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, {
      initialScreen: 'cart',
      initialCart: {
        [store_id]: 1,
      },
    });
  };

  if (isSubscribe && !total && !isInfinity) {
    return (<></>);
  }

  return (
    <ItemView disabled={view_disabled}>
      <Title disabled={view_disabled}>
        {view_name ? view_name : caption}
        <Description>{view_size}</Description>
      </Title>

      {isInfinity ? (
        <Count disabled={view_disabled}>Infinity</Count>
      ) : (
        <Count disabled={view_disabled}>
          {count} {isSubscribe && `${translations.t('cabinet.balance.from')} ${total}`}
        </Count>
      )}

      {!isSubscribe && !Boolean(team.id) &&
        <Add>
          <ButtonAdd onClick={openStore} disabled={view_disabled} />
        </Add>
      }
    </ItemView>
  );
};

const ItemView = styled.tr`
  ${Mixins.transition('background-color')};
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.feldgrau)};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
`;
export const TD = styled.td`
  padding-top: 20px;
  vertical-align: middle;
`;
const Title = styled(TD)`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 0px;
  padding-right: 15px;
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.main_dark)};
  width: 255px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Count = styled(TD)`
  padding: 20px 0 0 34px;
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.main_dark)};
  font-size: 20px;
  display: flex;
  min-width: 115px;
`;
const Add = styled(TD)`
  padding-right: 0;
`;
const Description = styled.div`
  color: ${Colors.second_gray};
  font-size: 12px;
`;
