import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TooltipEditor } from 'planoplan-ui-kit/core/tooltipEditor';

export const PermissionsUsers = ({ folder = {} }) => {
  const { profile, team: { members }} = useSelector(state => state);
  const maxDisplayUsers = 3;
  const usersPermissions = folder?.permissions?.edit || [];
  const users = useMemo(() => {
    return members.filter(member => usersPermissions.includes(member.id)).sort((a) => a.id === profile.id ? -1 : 1);
  }, [folder, members]);

  return (
    <View>
      {users.slice(0, maxDisplayUsers).map((user, index) => (
        <TooltipEditor key={user.id} theme={'editor_night'} render={user.name || user.email} with={'300px'} direction={'top'} nowrap>
          <Avatar index={maxDisplayUsers - index} src={user.avatar} />
        </TooltipEditor>
      ))}
      
      {users.length > maxDisplayUsers && (
        <MoreAvatar>+{users.length - maxDisplayUsers}</MoreAvatar>
      )}
    </View>
  )
};

const View = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${Colors.second_gray};
  z-index: ${({ index }) => index};
  position: relative;
  object-fit: cover;
  cursor: default;
  margin-left: -4px;

  &:first-child {
    margin-left: 0;
  }
`;
const MoreAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -4px;
  background-color: ${Colors.second_gray};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${Colors.white};
`;