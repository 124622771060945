import React, { useState, useMemo } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as effects from '@features/cabinet/pages/layouts/effects';
import { HEADER_MODES, ROW_TYPE } from '@features/cabinet/pages/layouts/constants';
import { PermissionsUsers } from './permissionsUsers';
import { ListEdit } from './listEdit';
import { EditIcon } from '../molecules/editIcon';
import { IconPin } from '../../../components/icons';
import { checkPermissionsFolder } from '../helpers';

export const Folder = ({ folder = {}, editList = [], currentHeaderMode = '', setFolderId = () => {} }) => {
  const dispatch = useDispatch();
  const { team } = useSelector(state => state);
  const [isOpenEditList, setIsOpenEditList] = useState(false);
  const [isChangeName, setIsChangeName] = useState(false);
  const isPin = folder.pinned_at;
  const selectActive = currentHeaderMode === HEADER_MODES.select;
  const disabledEdit = useMemo(() => !dispatch(checkPermissionsFolder(folder, { onlyAdmin: true })), []);

  const onChangeFolderName = (id, value) => {
    dispatch(effects.setProjectFolderName(id, value));
    setIsChangeName(true);
  };
  const onSaveFolderName = (id, value) => {
    if (isChangeName) {
      dispatch(effects.saveFolderName(id, value));
      setIsChangeName(false);
    }
  };
  const onSelected = () => dispatch(effects.onSelect(folder.id, ROW_TYPE.folder, !Boolean(folder.selected)));
  const onSetFolder = () => setFolderId(folder.id);

  return (
    <View selectActive={selectActive} isSelected={folder.selected}>
      {selectActive && <SelectedOverlay isSelected={folder.selected} onClick={onSelected} />}
      <Preview isPin={isPin} onClick={onSetFolder}>
        {isPin &&
          <Pin>
            <IconPin />
          </Pin>
        }
        <Name value={folder.name}
              disabled={disabledEdit}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => onChangeFolderName(folder.id, event.target.value)}
              onBlur={(event) => onSaveFolderName(folder.id, event.target.value)}/>
      </Preview>

      {Boolean(team.id) && (
        <PermissionsUsersWrap>
          <PermissionsUsers folder={folder} />
        </PermissionsUsersWrap>
      )}

      <Info>
        <Bottom>
          <Edit>
            <EditIcon disabled={disabledEdit} isOpen={isOpenEditList} onClick={() => setIsOpenEditList(true)} />

            {isOpenEditList &&
              <ListEdit id={folder.id} list={editList} onClose={() => {setIsOpenEditList(false)}}/>
            }
          </Edit>
        </Bottom>
      </Info>
    </View>
  )
};

const Name = styled.input`
  ${Mixins.transition('color')};
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 24px;
  line-height: 28px;
  padding: 0;
  color: ${Colors.feldgrau};
  border-bottom: 1px solid transparent;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border-bottom-color: ${Colors.feldgrau25};
  }
`;
const SelectedOverlay = styled.div`
  border: 4px solid ${({ isSelected }) => isSelected ? Colors.main_blue : 'transparent'};
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
`;
const View = styled.div`
  ${Mixins.transition('background-color')};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #E1E1E1;
  position: relative;  
  
  ${({ selectActive }) => !selectActive && `
    &:hover {
      background-color: ${Colors.main_gray};
      
      ${Name} {
        color: ${Colors.white};
      }
    }
  `}
  
  ${({ selectActive, isSelected }) => (selectActive && !isSelected) && `
    &:hover {
      ${SelectedOverlay} {
        border: 4px solid ${rgba(Colors.main_blue, 0.5)};
      }
    }
  `}
`;

const Preview = styled.div`
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  border-radius: 5px 5px 0 0;
  padding: 12px ${({ isPin }) => isPin ? 55 : 12}px 12px 12px;
`;
const PermissionsUsersWrap = styled.div`
  margin-bottom: 38px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  position: absolute;
  bottom: 32px;
  right: 0;
`;
const Pin = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
`;
const Info = styled.div`
  padding: 8px 12px;
  flex-shrink: 0;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Edit = styled.div``;