import { useState } from 'react';
import { ScrollLock, StatusInterfaces } from '@libs';
import { POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';

export const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = (root) => {
    StatusInterfaces.closeBanners();
    setIsOpenModal(false);

    if(root) {
      ScrollLock.unlock(root);

      if (!StatusInterfaces.checkSomeoneOpen()) {
        window.invokeEditorAction({
          name: interfaces[POINTER_ON_INTERFACE].name,
          value: interfaces[POINTER_ON_INTERFACE].value,
        });
      }
    }
  };

  return [isOpenModal, openModal, closeModal];
};
