import React from 'react';
import ReactDOM from 'react-dom';
import { getLocaleUi, StatusInterfaces } from '@libs';
import { App } from './app';
import { createAppStore, initialState } from './modules/Store';
import { interfaces } from '@globalInvoke';
import { ON_GALLERY_SHOW, ON_SAVE_IMAGE_EDITOR } from '@observer/constants';
import { mappedButtons } from './modules/invoke';
import { POINTER_ON_INTERFACE, POINTER_OFF_INTERFACE, MODE_ALIAS, UNITY } from '@globalConstants';
import { ApiCall } from './modules/api-call';
import { Provider } from "react-redux";
import { BUTTONS } from './constants';
import ErrorBoundary from './ErrorBoundary';

const observer = new window.POPObserver();

export default class UIImageEditor {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения редактора изображений не указан html элемент');
    this.mode = settings.mode || 'unity'; // unity, site
    this.locale = settings.locale || 'ru';
    this.localeUi = getLocaleUi(this.locale);
    this.zoom = settings.zoom || 1;
    this.webgl = settings.webgl;
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    this.src = params.src;
    this.params = params.params || {};
    this.additional = params.additionalParams || {};

    this._mount();
    this._editorInvoke();

    ApiCall.setSettings({ locale: this.locale });
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary onClose={this.close.bind(this)}>
          <App />
        </ErrorBoundary>
      </Provider>,
      rootElement
    );
    StatusInterfaces.openImageEditor();
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      image: { src: this.src },
      settings: {
        root: this.root,
        mode: this.mode,
        locale: this.locale,
        localeUi: this.localeUi, // язык для интерфейса из массива поддерживаемых языков
        close: this.close.bind(this),
        zoom: this.zoom,
        params: this.params,  // параметры которые возвратятся в колбеке
        webgl: this.webgl,
        additional: this.additional,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    if (MODE_ALIAS[this.mode] === UNITY) {
      window.invokeEditorAction({
        name: interfaces[POINTER_OFF_INTERFACE].name,
        value: interfaces[POINTER_OFF_INTERFACE].value,
      });
    }
  }

  /**
   * Удаление модуля из дом-дерева, и триггер событий для юнити
   * @param mode {string} - режимы закрытия
   * @param params {object} - Параметры: image - изображение в base64, mode - вид сохранения изображения (new|save)
   */
  close(mode = '', params = {}) {
    observer.postMessage(ON_GALLERY_SHOW);

    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    StatusInterfaces.closeImageEditor();
    observer.postMessage(ON_SAVE_IMAGE_EDITOR, params);

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.IMAGE_EDITOR_SAVE].name,
      value: params
    });

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.IMAGE_EDITOR_CLOSE].name,
      value: mappedButtons[BUTTONS.IMAGE_EDITOR_CLOSE].value
    });

    if (!StatusInterfaces.checkSomeoneOpen()) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }
}

if (!window.UIImageEditor) {
  window.UIImageEditor = UIImageEditor;
}
