import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { notifications } from '@features/notifications';
import { MODE_ALIAS, UNITY } from '@globalConstants';
import { StatusInterfaces } from '@libs';
import { laptop } from './modules/breakpoints';
import { Indent } from './modules/indent';
import { GlobalStyles } from './modules/styles';
import { Sidebar } from './components/Sidebar';
import { Index } from './screens';
import { VideoPopup } from './components/VideoPopup';
import { Close } from './components/Close';
import { StoreContext } from './modules/Store';
import { mappedButtons } from './modules/invoke';
import * as effects from './modules/effects';
import * as getdata from './modules/get-data';
import { PAY_STATUSES, PAYMENT_SUCCESSFUL } from '../store/constants';

/* Порядок выполнения
1. Все запросы к бэку
2. Как пришел ответ от /getgoods - по тарифу делаем запрос за баннерами
3. После получения данных - устанавливаем продукты и достуные экраны
4. Если нет orderid, то проверяем параметры иницилизации и устанавливаем требуемый экран
5. Если есть orderid, то проверяем статус платежа, и устанавливаем экран корзины
*/

export default () => {
  const [state, dispatch] = useContext(StoreContext);
  const { pricing, profileGoods, settings, products, lastBuy, banners, availableScreens, payment } = state;
  const { orderid, redirectStatus, mode, closeAfterPay, callbackAfterSuccessPay } = settings;

  useEffect(() => {
    getdata.getTranslations(dispatch, state);
    getdata.getGoods(dispatch, state);
    getdata.getProfile(dispatch, state);
    getdata.getPricing(dispatch, state);
    getdata.getUserSettings(dispatch, state);
    getdata.getBindingCard(dispatch, state);
    getdata.getPaymentMethods(dispatch, state);
    getdata.getCountries(dispatch, state);
    getdata.getContentStore(dispatch, state);

    if (!StatusInterfaces.checkAnyOneOpen(['cabinet', 'gallery'])) {
      notifications.init('store_notifications');
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.values(profileGoods).length) {
      getdata.getBanners(dispatch, state);
    }
  }, [profileGoods]);

  useEffect(() => {
    if (Object.values(profileGoods).length && pricing.length) {
      effects.setProducts(dispatch, state);
    }
  }, [profileGoods, pricing, lastBuy]);

  useEffect(() => {
    if (products.length && banners) {
      effects.setAvailableScreens(dispatch, state);
    }
  }, [products.length, banners]);

  useEffect(() => {
    if (!availableScreens.length) {
      return;
    }

    if ((orderid || redirectStatus) && !payment.status) {
      effects.checkPayStatus(dispatch, state);
    }

    if (!orderid) {
      if (!payment.status) {
        effects.checkInitialScreen(dispatch, state);
      }

      effects.checkInitialCart(dispatch, state);
      effects.checkOpenProduct(dispatch, state);
    }
  }, [availableScreens]);

  useEffect(() => {
    if (payment.status === PAY_STATUSES.succeeded) {
      getdata.getProfile(dispatch, state);
      getdata.getGoods(dispatch, state);

      if (closeAfterPay) {
        setTimeout(() => {
          effects.closeStore(state);
          callbackAfterSuccessPay();
        }, 2000);
      } else {
        callbackAfterSuccessPay();
      }

      if (MODE_ALIAS[mode] === UNITY) {
        window.invokeEditorAction({
          name: mappedButtons[PAYMENT_SUCCESSFUL].name,
          value: mappedButtons[PAYMENT_SUCCESSFUL].value,
        });
      }
    }
  }, [payment]);

  return (
    <>
      <GlobalStyles />
      <Container id="store_container">
        <Wrapper>
          <Content>
            <CloseIcon>
              <Close width="38px" height="38px" />
            </CloseIcon>
            <Sidebar />
            <Index />
            <VideoPopup />
          </Content>
        </Wrapper>
        <div id="store_notifications" />
      </Container>
    </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  background-color: ${rgba(Colors.black, 0.4)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 998;
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
`;

const Content = styled.div`
  ${Indent.margin_top(2)};
  ${Indent.margin_bottom(2)};
  width: ${Grid.col(32)};
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
  overflow: hidden;

  @media ${laptop} {
    ${Grid.row()};
    width: ${Grid.col(30)};
    overflow: visible;
  }
`;

const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: -20px;
  transform: translateX(100%);

  @media ${laptop} {
    display: block;
  }
`;
