import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { actions } from '@globalInvoke';
import { PAUSE_TRACK, RESUME_TRACK } from '@globalConstants';
import { ON_LANGUAGE_LOAD_FOR_PAGE } from '@observer/constants';
import { translations } from '@libs';
import { Analytics } from '../../modules/analytics';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Input } from '../../components/form';
import { Button } from 'planoplan-ui-kit/core/button';
import * as effects from './effects';
import { LoginSocial } from './organism/loginSocial';
import { Close } from '../../components/Close'

const observer = new window.POPObserver();

export const PageLoginSettings = () => {
  const { profile } = useSelector(state => state);
  const dispatch = useDispatch();
  const [passwords, setPasswords] = useState({
    current: '',
    newPass: '',
    repeat: '',
  });

  const onChangePassword = () => {
    dispatch(effects.onChangePassword(passwords));
  };

  useEffect(() => {
    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    Analytics.viewLoginSettings();
    observer.addEventListener(ON_LANGUAGE_LOAD_FOR_PAGE, () => Analytics.viewLoginSettings());
    
    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
      observer.removeEventListener(ON_LANGUAGE_LOAD_FOR_PAGE);
    }
  }, []);

  return (
    <View>
      <Top>
        <Title>{translations.t('cabinet.login_settings.title')}</Title>
        <Close />
      </Top>

      <Wrapper>
        <Password>
          <PasswordTitle>{profile.use_pwd ? translations.t('cabinet.login_settings.caption') : translations.t('cabinet.login_settings.caption.no_pass')}</PasswordTitle>

          <InputWrap>
            <Input id='currentPassword'
              value={passwords.current}
              disabled={!profile.use_pwd}
              label={translations.t('cabinet.login_settings.current_password')}
              placeholder={profile.use_pwd ? translations.t('cabinet.login_settings.current_password.placeholder') : translations.t('cabinet.login_settings.current_password.placeholder.no_pass')}
              type='password'
              onChange={(event) => setPasswords({ ...passwords, current: event.target.value })} />
          </InputWrap>

          <InputWrap>
            <Input id='currentPassword'
              value={passwords.newPass}
              label={translations.t('cabinet.login_settings.new_password')}
              placeholder={translations.t('cabinet.login_settings.new_password.placeholder')}
              type='password'
              onChange={(event) => setPasswords({ ...passwords, newPass: event.target.value })} />
          </InputWrap>

          <InputWrap>
            <Input id='currentPassword'
              value={passwords.repeat}
              label={translations.t('cabinet.login_settings.repeat_password')}
              placeholder={translations.t('cabinet.login_settings.repeat_password.placeholder')}
              type='password'
              onChange={(event) => setPasswords({ ...passwords, repeat: event.target.value })} />
          </InputWrap>

          <Save>
            <Button theme="primary" size="S" onClick={onChangePassword}>{translations.t('cabinet.login_settings.save')}</Button>
          </Save>
        </Password>

        <LoginSocial />
      </Wrapper>
    </View>
  );
};

const View = styled.div`
  padding: 20px;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-left: 6px;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Block = styled.div`
  border: 1px solid ${Colors.light_gray};
  padding: 20px;
  max-width: 360px;
`;
const Password = styled(Block)`
  border-radius: 5px;
  flex-shrink: 0;
`;
const PasswordTitle = styled.div`
  color: ${Colors.main_dark};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
`;
const InputWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const Save = styled.div``;
const Social = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  margin-left: -1px;
  padding: 20px;
  max-width: 350px;
  border-radius: 0 5px 5px 0;
`;

const SocialTitle = styled.div`
  color: ${Colors.feldgrau50};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
`;
const SocialBody = styled.div`
`;
