import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import * as effects from '../effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconClose } from '../../../components/icons';
import { ROW_TYPE } from '../constants';
import { TARIFF_ALIAS } from '../../../constants';
import { checkPermissionsFolder } from '../helpers';

export const Select = ({
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  onDelete = () => {},
  onDuplicate = () => {},
  moveToTeam = () => {},
  moveToFolder = () => {},
}) => {
  const dispatch = useDispatch();
  const { team, tariff, projects: { items, currentFolder }} = useSelector(state => state);
  const teams = team.list;
  // Выделить все или Очистить все
  const [selectAll, setSelectAll] = useState(true);
  // Все выделенные проекты и папки
  const [selectedEIds, setSelectedEIds] = useState([]);
  const disabledButton = !selectedEIds.length;
  const disabledEdit = useMemo(() => !dispatch(checkPermissionsFolder()), [team, currentFolder]);

  const onSelectAll = () => {
    dispatch(effects.onAllSelected(selectAll));
    setSelectAll(!selectAll);
  };
  const onCloseAndReset = () => {
    dispatch(effects.onAllSelected(false));
    setSelectAll(true);
    onClose();
  };
  const filterAndNormalizeSelected = (items) => {
    return items.filter((item) => item.selected).map((item) => {
      const prefix = item.row_type === ROW_TYPE.project ? 'p' : 'f';

      return `${prefix}_${item.id}`;
    });
  };

  useEffect(() => {
    setSelectedEIds(filterAndNormalizeSelected(items));
  }, [items]);

  return (
    <View>
      {!isOpen && <Button disabled={disabledEdit} onClick={onOpen}>{translations.t('cabinet.projects.select')}</Button>}

      {isOpen &&
        <SelectActions>
          <ActionsWrap>
            <Button disabled={disabledButton} onClick={() => moveToFolder({ ids: selectedEIds })}>{translations.t('cabinet.projects.moveToFolder')}</Button>
            {(teams && Boolean(teams.length)) &&
              <Button disabled={disabledButton} onClick={() => moveToTeam({ ids: selectedEIds })}>{translations.t('cabinet.projects.moveToTeam')}</Button>
            }
            <Button disabled={disabledButton || tariff.alias === TARIFF_ALIAS.VIEW_ONLY} onClick={() => onDuplicate({ ids: selectedEIds })}>{translations.t('cabinet.projects.duplicate')}</Button>
            <Button disabled={disabledButton} onClick={() => onDelete({ ids: selectedEIds })}>{translations.t('cabinet.projects.delete')}</Button>
          </ActionsWrap>
          <ActionsWrap>
            <Button onClick={onSelectAll}>{selectAll ? translations.t('cabinet.projects.selectAll') : translations.t('cabinet.projects.clearAll')}</Button>
            <ButtonClose onClick={onCloseAndReset}><IconClose /></ButtonClose>
          </ActionsWrap>
        </SelectActions>
      }
    </View>
  )
};

const View = styled.div`
`;
const SelectActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ActionsWrap = styled.div`
  display: flex;
`;
const Button = styled.div`
  ${Mixins.transition('background-color, border-color, box-shadow, color')};
  height: 40px;
  padding: 0 14px;
  border-radius: 55px;
  border: 1px solid ${Colors.main_dark};
  font-size: 16px;
  color: ${Colors.main_dark};
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  
  &:hover {
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    color: ${Colors.white};
    background-color: ${Colors.main_black};
    box-shadow: none;
  }
  
  &:not(:last-child) {
    margin-right: 10px;
  }
  
  ${({ disabled }) => disabled && `
    pointer-events: none;
    border: 1px solid ${Colors.light_gray};
    color: ${Colors.light_gray};
  `}
`;
const ButtonClose = styled(Button)`
  border-radius: 50%;
  width: 40px;
  justify-content: center;
  padding: 0;
  
  svg {
    ${Mixins.transition('fill')};
  }
  
  &:hover {
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    background-color: ${Colors.main_black};
    box-shadow: none;

    svg path {
      fill: ${Colors.white};
    }
  }
`;
