import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.GALLERY_CLOSE]: {
    name: 'GalleryClose',
    value: true,
    type: 'string',
    alias: 'Close_Gallery',
  },
  [BUTTONS.SAVE_PDF]: {
    name: 'GallerySavePDF',
    value: {},
    type: 'object',
    alias: 'Gallery_Save_PDF',
  },
};
