let instance = null;

class StatusInterfacesClass {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.interfaces = {
      screenEntry: {
        open: false,
      },
      formEntry: {
        open: false,
      },
      cabinet: {
        open: false,
      },
      gallery: {
        open: false,
      },
      designProject: {
        open: false,
      },
      imageEditor: {
        open: false,
      },
      tourEditor: {
        open: false,
      },
      store: {
        open: false,
      },
      quickStore: {
        open: false,
      },
      gettingStarted: {
        open: false,
      },
      banners: {
        open: false,
      },
      infoPoints: {
        open: false,
      },
      projects: {
        open: false,
      },
    };
    
    return instance;
  }

  _setStatusOpenness(name = {}, status) {
    try {
      name.open = status;
    } catch (error) {
      console.error(error);
    }
  }

  checkAnyOneOpen(names = []) {
    try {
      for (let key = 0; key < names.length; key++) {
        const name = names[key];
        
        if (this.interfaces[name].open) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  // Проверяем открыт ли какой нибудь интерфейс
  checkSomeoneOpen() {
    try {
      for (const key in this.interfaces) {
        if (Object.prototype.hasOwnProperty.call(this.interfaces, key)) {
          if (this.interfaces[key].open) {
            return true;
          }
        }
      }

      return false;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  openScreenEntry() {
    this._setStatusOpenness(this.interfaces.screenEntry, true);
  }

  closeScreenEntry() {
    this._setStatusOpenness(this.interfaces.screenEntry, false);
  }
  
  openFormEntry() {
    this._setStatusOpenness(this.interfaces.formEntry, true);
  }

  closeFormEntry() {
    this._setStatusOpenness(this.interfaces.formEntry, false);
  }

  openCabinet() {
    this._setStatusOpenness(this.interfaces.cabinet, true);
  }

  closeCabinet() {
    this._setStatusOpenness(this.interfaces.cabinet, false);
  }
  
  openGallery() {
    this._setStatusOpenness(this.interfaces.gallery, true);
  }

  closeGallery() {
    this._setStatusOpenness(this.interfaces.gallery, false);
  }

  openDesignProject() {
    this._setStatusOpenness(this.interfaces.designProject, true);
  }

  closeDesignProject() {
    this._setStatusOpenness(this.interfaces.designProject, false);
  }

  openImageEditor() {
    this._setStatusOpenness(this.interfaces.imageEditor, true);
  }

  closeImageEditor() {
    this._setStatusOpenness(this.interfaces.imageEditor, false);
  }

  openTourEditor() {
    this._setStatusOpenness(this.interfaces.tourEditor, true);
  }

  closeTourEditor() {
    this._setStatusOpenness(this.interfaces.tourEditor, false);
  }

  openStore() {
    this._setStatusOpenness(this.interfaces.store, true);
  }

  closeStore() {
    this._setStatusOpenness(this.interfaces.store, false);
  }

  openQuickStore() {
    this._setStatusOpenness(this.interfaces.quickStore, true);
  }

  closeQuickStore() {
    this._setStatusOpenness(this.interfaces.quickStore, false);
  }

  openGettingStarted() {
    this._setStatusOpenness(this.interfaces.gettingStarted, true);
  }

  closeGettingStarted() {
    this._setStatusOpenness(this.interfaces.gettingStarted, false);
  }

  openBanners() {
    this._setStatusOpenness(this.interfaces.banners, true);
  }

  closeBanners() {
    this._setStatusOpenness(this.interfaces.banners, false);
  }

  openInfoPoints() {
    this._setStatusOpenness(this.interfaces.infoPoints, true);
  }

  closeInfoPoints() {
    this._setStatusOpenness(this.interfaces.infoPoints, false);
  }

  openProjects() {
    this._setStatusOpenness(this.interfaces.projects, true);
  }

  closeProjects() {
    this._setStatusOpenness(this.interfaces.projects, false);
  }
}
export const StatusInterfaces = new StatusInterfacesClass();
