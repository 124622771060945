import { saveAs } from 'file-saver';
import copy from 'copy-to-clipboard';
import { getExtFile, translations, isProdHost, StatusInterfaces } from '@libs';
import { notifications } from '@features/notifications';
import { MODE_ALIAS, UNITY, SITE, PLATFORMS, PLATFORMS_ALIAS } from '@globalConstants';
import { ON_ROUTE, ON_OPEN_IMAGE_EDITOR, ON_OPEN_STORE, ON_OPEN_QUICK_STORE } from '@observer/constants';
import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_CURRENT_IMAGE,
  SET_CURRENT_FILTER,
  SET_IMAGES,
  BUTTONS,
  SET_CHECKED_IMAGES,
  SET_DOWNLOAD_ALL,
  SET_REMOVE,
  MODE_CLOSE,
  SET_LAZY_IMAGES,
  SET_RELOAD_GALLERY,
  SAVE_MODE,
  CHECK_PRODUCT,
  GET_BINDING_CARD,
  PAY_GOODS,
  PAY_PRODUCT,
  CREATE_RENDER,
  TARIFF_ALIAS,
  RENDER_SIZES_ORDER,
  RENDER_SIZES_ALIAS
} from '../constants';
import { getNextImage, getPrevImage } from './helpers';
import { mappedButtons } from './invoke';
import * as getData from './getdata';
import { loadingImage, getGoodsPackage, getResolutionByGoodsParams, getNameResolutionByWidth } from './helpers';
import { ApiCall } from './api-call';

const observer = new window.POPObserver();

// Загрузка и установка текущей картинки
export const setCurrentImage = (image) => (dispatch, getState) => {
  if (!image) {
    return;
  }

  const { lazy_images } = getState();
  const { view_id, original } = image;

  const current_lazy = lazy_images[image.view_id];
  const isLoaded = current_lazy ? current_lazy.isLoaded : true;

  image.isLoaded = isLoaded;

  if (!isLoaded) {
    loadingImage(original).then(() => {
      lazy_images[view_id].isLoaded = true;
      image.isLoaded = true;

      dispatch({ type: SET_LAZY_IMAGES, payload: lazy_images });
      dispatch(lazyLoadingImages(lazy_images, current_lazy));
    });
  }

  dispatch({ type: SET_CURRENT_IMAGE, payload: image });
};

// Лэзи-лоадинг +- 3 картинок от текущей
export const lazyLoadingImages = (lazy_images, current_lazy) => (dispatch) => {
  const loaded_images = [];
  const positions = [-3, -2, -1, 1, 2, 3]; // ищем в объекте не через цикл, а по ключам в объекте

  // собираем массив для предзагрузки, исходя из текущей позиции картинки)
  positions.forEach((item) => {
    const image = lazy_images[current_lazy.id + item];

    if (image && !image.isLoaded) {
      loaded_images.push(image);
    }
  });

  loaded_images.forEach((item) => {
    const current = lazy_images[item.id];

    if (!current.isLoaded) {
      loadingImage(item.original).then(() => {
        current.isLoaded = true;

        dispatch({ type: SET_LAZY_IMAGES, payload: lazy_images });
      });
    }
  });
};

// Стрелка вперед
export const setNext = () => (dispatch, getState) => {
  const state = getState();
  const { current_image, images } = state;
  const next_image = getNextImage(images, current_image);

  dispatch(setCurrentImage(next_image));
};

// Стрелка назад
export const setPrev = () => (dispatch, getState) => {
  const state = getState();
  const { current_image, images } = state;
  const prev_image = getPrevImage(images, current_image);

  dispatch(setCurrentImage(prev_image));
};

// Выбор картинки в сайдбаре
export const setCurrent = (image) => (dispatch) => {
  dispatch(setCurrentImage(image));
};

// Выбор фильтра
export const setCurrentFilter = ({ value }) => (dispatch, getState) => {
  const state = getState();
  const { images, filters, current_image } = state;

  const filteredImages = images.map((item) => {
    item.isFilter = item.filter === value || value === 'all';

    return item;
  });

  // если текущая картинка не подходит под фильтр, берем подходящую из массива
  const isFilteredCurrent = filteredImages.find((item) => item.view_id === current_image.view_id);

  if (isFilteredCurrent && !isFilteredCurrent.isFilter) {
    const filtered_image = filteredImages.find((item) => item.isFilter);

    dispatch(setCurrentImage(filtered_image));
  }

  dispatch({ type: SET_IMAGES, payload: filteredImages });
  dispatch({ type: SET_CURRENT_FILTER, payload: filters.find((item) => item.value === value) });
};

// Выбор картинки
export const checked = (value, image) => (dispatch, getState) => {
  const images = getState().checked_images;

  // Если чекбокс чекнули, то добавляем элемент в общий массив, если нет, то возвращаем новый без него
  const checked_images = value ? [...images, image] : images.filter((item) => item.view_id !== image.view_id);

  dispatch({ type: SET_CHECKED_IMAGES, payload: checked_images });
};

// Чекбокс выбрать все
export const checkedAll = (value) => (dispatch, getState) => {
  const { images } = getState();

  // Если чекбокс чекнули, то добавляем все элементы подходящие под текущий фильтр, если нет, возвращаем пустой массив
  const checked_images = value ? images.filter((item) => item.isFilter && item.isAvailableCheck) : [];

  dispatch({ type: SET_CHECKED_IMAGES, payload: checked_images });
};

// Загрузка 1 картинки (кнопка в баре)
export const download = (item) => (dispatch, getState) => {
  const { settings } = getState();
  const { mode, platform } = settings;

  if (MODE_ALIAS[mode] === UNITY && PLATFORMS_ALIAS[platform] === PLATFORMS.STANDALONE) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.DOWNLOAD].name,
      value: [item.editor],
    });

    return;
  }

  const { original, caption } = item;
  const name = `${caption}.${getExtFile(original)}`;

  saveAs(original, name);
};

// Мультизагрузка (кнопка в сайдбаре)
export const downloadAll = () => async (dispatch, getState) => {
  const { checked_images, settings } = getState();
  const { projectId, mode, platform } = settings;

  if (MODE_ALIAS[mode] === UNITY && PLATFORMS_ALIAS[platform] === PLATFORMS.STANDALONE) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.DOWNLOAD].name,
      value: checked_images.map((item) => item.editor),
    });

    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: SET_DOWNLOAD_ALL } });

  //const urls = { local: '/', prod: '/projects/exportScreenshots/' };
  const images = checked_images.map((item) => ({
    'sid[]': item.id,
  }));
  const params = { id: projectId, images };

  try {
    const response = await ApiCall.getZipImages({ params });

    if (!response.data) {
      throw new Error();
    }

    const blob = new Blob([response.data], { type: 'application/zip' });
    saveAs(blob);

    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DOWNLOAD_ALL } });
    dispatch({ type: SET_CHECKED_IMAGES, payload: [] });
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DOWNLOAD_ALL } });
    notifications.showError(translations.t('gallery.zip.error'));
  }
};

// Удаление картинки
export const remove = (items) => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_REMOVE } });

  const { projectId } = getState().settings;
  const images = items.filter(item => item.type !== 'tour').map((item) => {
    if (item.isError) {
      return { 'tid[]': item.id };
    }

    return { 'sid[]': item.id };
  });
  const tours_id = items.filter(item => item.type === 'tour').map(item => ({'tours_id[]': item.id}));
  const params = { id: projectId, images, tours_id };

  try {
    const response = await ApiCall.removeImage({ params });
    const { data } = response;

    if (!data.success) {
      throw new Error(data.errorText);
    }

    notifications.showSuccess(translations.t('gallery.remove.success'));
    await dispatch(getData.getGallery());

    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_REMOVE } });
    dispatch({ type: SET_CHECKED_IMAGES, payload: [] });
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_REMOVE, error: error.message } });
    notifications.showError(translations.t('gallery.remove.error'));
  }
};

// Создание рендера, только для редактора
export const createRender = () => (dispatch, getState) => {
  const { current_image, settings } = getState();
  const project_id = settings.projectId;
  const value = { ...current_image.editor, project_id };

  window.invokeEditorAction({
    name: mappedButtons[BUTTONS.CREATE_RENDER].name,
    value,
  });
};

// Закрытие галереи и открытие магазина
export const openStore = (initialScreen) => (dispatch, getState) => {
  const { settings } = getState();
  const { team_id } = settings;

  settings.close(MODE_CLOSE.WITH_OPEN_UI);
  window.UIInvokeFunction('StoreOpen', { initialScreen, team_id });
};

// onSwipe
export const swipe = ({ direction }, isDisabledPan) => (dispatch, getState) => {
  if (!isDisabledPan) {
    return;
  }

  const { current_image } = getState();

  if (current_image.krpano_url || current_image.tour) {
    return;
  }

  if (direction === 2) {
    dispatch(setNext());
  }

  if (direction === 4) {
    dispatch(setPrev());
  }
};

// Поделиться
export const share = (id, locale = 'en', isTour = false) => {
  const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
  const url = `https://${host}/${locale}/preview/${id}/${isTour ? '?tour=1' : ''}`;

  copy(url);
  notifications.showSuccess(translations.t('gallery.copy.success'));
};

// Копировать код
export const copeTourCode = (id) => {
  const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
  const iframeCode = `
<!-- Planoplan tour -->
  <iframe src="https://${host}/etc/tourbuilder/viewer.html?id=${id}" width="100%" height="100%" style="min-height:500px;" frameborder="0" allowfullscreen></iframe>
<!-- End Planoplan tour -->
`;

  copy(iframeCode);
  notifications.showSuccess(translations.t('gallery.copy.success'));
};

// Редактировать
export const edit = ({ id, original, preview }) => async (dispatch, getState) => {
  const { project_name, images, current_image, settings: { projectId } } = getState();
  const additionalParams = {
    project_name,
    countRender: Array.isArray(images) ? images.length : 0,
    imageId: current_image.id,
    projectId
  };

  observer.postMessage(ON_OPEN_IMAGE_EDITOR, {
    params: {
      isGallery: true,
      id
    },
    src: {
      origin: original,
      // Убрал т.к. обрезает криво, особенно вертикальные рендеры.
      // compressed: getCompressUrl(original, 1920, 1080),
      thumb: preview,
    },
    additionalParams,
  });
};

// Сохранить отредактированное изображение
export const saveEditedImage = ({ id, mode, image }) => async (dispatch) => {
  if (!image) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: SET_RELOAD_GALLERY } });

  if (!StatusInterfaces.checkAnyOneOpen(['cabinet'])) {
    notifications.init('gallery-notifications');
  }

  const params = { id, mode, image };

  try {
    const response = await ApiCall.editImage({ params });
    const { data } = response;

    if (!data.success) {
      throw new Error(data.errorText);
    }

    if (mode === SAVE_MODE.new) {
      notifications.showSuccess(translations.t('gallery.edit.new'));
    } else if (mode === SAVE_MODE.current) {
      notifications.showSuccess(translations.t('gallery.edit.replace'));
    }

    await dispatch(getData.getGalleryImages({ selectNew: mode === SAVE_MODE.new }));

    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_RELOAD_GALLERY } });
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_RELOAD_GALLERY } });
    notifications.showError(translations.t('gallery.edit.error'));
  }
};

export const routeToHomePage = () => observer.postMessage(ON_ROUTE, '/');

export const deviceMotionRequest = () => {
  const isSupport = window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function'
    && typeof window.DeviceOrientationEvent.requestPermission === 'function';

  if (!isSupport) {
    return true
  }

  const motionRequest = window.DeviceMotionEvent.requestPermission();
  const orientationEvent =  window.DeviceOrientationEvent.requestPermission();

  Promise.all([motionRequest, orientationEvent])
    .then(states => {
      if (states[0] === 'granted' && states[1] === 'granted') {
        return true;
      }
    })
    .catch(console.error);
};

// get goods FullHD And 4K
export const getFullHDAnd4K = () => (dispatch, getState) => {
  try {
    const { profileGoods: { goods } } = getState();

    if (!goods || !goods.length) {
      return { goodsFullHD: {}, goods4K: {} };
    }

    const idFullHD = { dev: 'goods_4', prod: 'goods_4' };
    const id4K = { dev: 'goods_5', prod: 'goods_5' };
    const key = isProdHost ? 'prod' : 'dev';
    const goodsFullHD = goods.find((item) => item.store_id === idFullHD[key]);
    const goods4K = goods.find((item) => item.store_id === id4K[key]);

    return {
      goodsFullHD,
      goods4K,
    }
  } catch (error) {
    console.error(error);

    return {
      goodsFullHD: {},
      goods4K: {},
    }
  }
};

export const getRendersGoods = () => (dispatch, getState) => {
  try {
    const { profileGoods: { goods }, current_image } = getState();
    const currentRenderWidth = current_image?.size?.width;
    const currentRenderName = getNameResolutionByWidth(currentRenderWidth);
    const currentOrder = RENDER_SIZES_ORDER.indexOf(currentRenderName);

    if (!goods || !goods.length) {
      return {};
    }

    return goods.filter(item => item.alias === 'render' && !item.not_for_sale).map((item => {
      const { width, height } = getResolutionByGoodsParams(item);
      
      if (!width) return item;

      const resolutionName = getNameResolutionByWidth(width);
      const disabled = current_image.isPreview ? false : currentOrder >= RENDER_SIZES_ORDER.indexOf(resolutionName)

      if (!resolutionName) return item;

      return {
        ...item,
        disabled,
        prime: resolutionName === RENDER_SIZES_ALIAS['4K'],
        name: resolutionName
      };
    }));
  } catch (error) {
    console.error(error);

    return {}
  }
};

export const checkProduct = (goodsId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING_ON, payload: { name: CHECK_PRODUCT } });

    const { settings: { unity_clientID, app_id, projectId } } = getState();
    const { data } = await ApiCall.checkProduct({
      clientId: unity_clientID,
      appId: app_id,
      goodsId,
      projectId,
    });

    dispatch({ type: SET_LOADING_OFF, payload: { name: CHECK_PRODUCT } });

    return data.success || (data.data.deposit && data.data.deposit !== 0);
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: CHECK_PRODUCT } });

    throw error;
  }
};

export const getCurrentLinkedCard = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING_ON, payload: { name: GET_BINDING_CARD } });

    const { data } = await ApiCall.getBindingCards();

    if (!data.success || !data.data.items || !data.data.items.length) {
      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });

      return null;
    }

    const card = data.data.items.find((item) => Boolean(item.is_default));

    if (!card) {
      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });

      return null;
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });

    return card;
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });

    throw error;
  }
};

export const checkPay = (orderid = '', counter = 1) => {
  return new Promise(async (resolve, reject) => {
    const maxDelay = 10000;
    const startDelay = 1000;
    const attempts = 17;
    const delay = startDelay * counter > maxDelay ? maxDelay : startDelay * counter;

    const { data } = await ApiCall.checkPay({ orderid });

    if (!data.success) {
      return reject({ success: false, errorText: data.errorText });
    }

    if (counter > attempts) {
      notifications.showError(translations.t('gallery.render.pay.expired'));

      return reject({ success: false, errorText: translations.t('gallery.render.pay.expired') });
    }

    if (data.data.status === 'unpaid') {
      setTimeout(() => {
        checkPay(orderid, counter + 1).then(resolve).catch(reject);
      }, delay);
    } else if (data.data.status === 'paid') {
      return resolve({ success: true });
    } else {
      return reject({ success: false, errorText: '' });
    }
  });
};

export const payWithLinkedCard = (bindingCart, goods) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOADING_ON, payload: { name: PAY_GOODS } });

    const { profile: { email, country }} = getState();
    const goodsPackage = getGoodsPackage(goods);
    const params = {
      binding_id: bindingCart.id,
      receipt_email: email,
      country: country.IsoCode,
      'type[]': goodsPackage.store_id,
      mode: bindingCart.type,
    };
    const { data } = await ApiCall.pay(params);

    if (!data.success) {
      throw data.errorText;
    }

    if (data.data.status === 'processing' || data.data.status === 'pending') {
      const result = await checkPay(data.data.orderid);

      if (result.success) {
        notifications.showSuccess(translations.t('gallery.render.pay.success'));
      } else {
        throw result.errorText;
      }
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: PAY_GOODS } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: PAY_GOODS } });
    throw error;
  }
};

export const payProduct = (goods, onClose) => async (dispatch, getState) => {
  const getResolution = (stringParams) => {
    try {
      return stringParams.match(/resolution=\d+x\d+/g)[0].split('=')[1];
    } catch (error) {
      console.error(error);

      return '';
    }
  };

  try {
    dispatch({ type: SET_LOADING_ON, payload: { name: PAY_PRODUCT } });

    const resolution = getResolution(goods.params);
    const { settings: { unity_clientID, app_id, projectId }, current_image } = getState();
    const { data } = await ApiCall.payProduct({
      appId: app_id,
      clientId: unity_clientID,
      goodsId: goods.id,
      projectId,
      screenshotId: current_image.id,
      resolution,
    });

    if (!data.success) {
      throw data.errorText;
    }

    notifications.showSuccess(translations.t('gallery.render.improve.success'));
    dispatch({ type: SET_LOADING_OFF, payload: { name: PAY_PRODUCT } });
    onClose();
  } catch (error) {
    console.error(error);
    dispatch({ type: SET_LOADING_OFF, payload: { name: PAY_PRODUCT } });
    throw error;
  }
};

export const toRenderPreview = (goods = {}, onClose = () => {}) => async (dispatch, getState) => {
  const checkIsHasAndPayProduct = async () => {
    dispatch({ type: SET_LOADING_ON, payload: { name: CREATE_RENDER } });

    const isHasProduct = await dispatch(checkProduct(goods.id));

    if (isHasProduct) {
      await dispatch(payProduct(goods, onClose));
    } 

    dispatch({ type: SET_LOADING_OFF, payload: { name: CREATE_RENDER } });

    return isHasProduct;
  };

  try {
    const isHasAndPayProduct = await checkIsHasAndPayProduct();

    if (!isHasAndPayProduct) {
      observer.postMessage(ON_OPEN_STORE, {
        initialScreen: 'cart',
        initialCart: {
          [`${goods.store_id}`]: 1,
        },
        closeAfterPay: true,
        callbackAfterSuccessPay: async () => {
          await checkIsHasAndPayProduct();

          dispatch(getData.getGallery({ reload: true }));
        }
      });
    }

    return Boolean(isHasAndPayProduct);
  } catch (error) {
    console.error(error);

    dispatch({ type: SET_LOADING_OFF, payload: { name: CREATE_RENDER } });
    notifications.showError(translations.t('gallery.render.improve.error'));

    return false;
  }
};

export const onOpenQuickStoreWithProplus = () => async (dispatch, getState) => {
  try {
    const { profileGoods, settings: { team_id, projectId }} = getState();
    const goods = profileGoods?.goods;

    if (!goods || !goods.length) {
      console.error(new Error('Goods not found'));

      return;
    }

    const tariff = goods.find(item => item?.extra_data?.tariff_alias === TARIFF_ALIAS.PROPLUS && item?.extra_data?.duration === 12);

    if (!tariff || !tariff.id) {
      console.error(new Error('Tariff not found'));

      return;
    }

    observer.postMessage(ON_OPEN_QUICK_STORE, {
      goods: tariff.id,
      bannerAlias: 'locked.virtualtour',
      team_id,
      project_id: projectId,
    });
  } catch (error) {
    console.error(error);
  }
};
