import { request } from '@libs';

export const ApiCall = {
  getUserSettings({ team_id }) {
    const prod = team_id
      ? `/teams/getsettings/?name[]=tariffAlias&team_id=${team_id}`
      : `/users/getsettings/?name[]=tariffAlias`;

    const urls = {
      local: `/json/info-points/user-settings.json?name[]=tariffAlias&team_id=${team_id}`,
      prod,
    };

    return request.call_auth('get', urls);
  },

  getBanners({ locale, group }) {
    const params = `?lang=${locale}&group=${group}`;
    const urls = { local: `/json/banners/${group}.json`, prod: `/${locale}/promo/${params}` };

    return request.call_public('get', urls);
  },

  saveUserTariffSettings({ tariff, team_id, checksum }) {
    let prod = team_id
      ? `/teams/settings/?name=tariffAlias&value=${tariff}&team_id=${team_id}`
      : `/users/settings/?name=tariffAlias&value=${tariff}`;

    if (checksum) {
      prod = `${prod}&checksum=${checksum}`;
    }

    const urls = {
      local: `/json/info-points/user-settings.json?name=tariffAlias&value=${tariff}&team_id=${team_id}`,
      prod,
    };

    return request.call_auth('get', urls);
  },

  saveUserSettings({ team_id, name, value, checksum }) {
    let prod = team_id
      ? `/teams/settings/?name=${name}&value=${value}&team_id=${team_id}`
      : `/users/settings/?name=${name}&value=${value}`;

    if (checksum) {
      prod = `${prod}&checksum=${checksum}`;
    }

    const urls = {
      local: `/json/info-points/user-settings.json`,
      prod,
    };

    return request.call_auth('get', urls);
  },

  getTariffs({ locale }) {
    const local = `https://pp.ksdev.ru/ru/content/`;
    const params = { ['groups[]']: 'tariffs' };
    const urls = { local, prod: `/${locale}/content/` };

    return request.call_public('get', urls, params);
  },

  getPricing({ locale }) {
    const urls = { local: '/json/banners/pricing.json', prod: `/${locale}/getpricing/?format=json` };

    return request.call_auth('get', urls);
  },
};
