import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { useFullScreen } from 'planoplan-ui-kit/core/hooks/useFullScreen';
import { StatusInterfaces } from '@libs';
import { GlobalStyles } from './modules/styles';
import * as getData from './modules/getdata';
import * as effects from './modules/effects';
import { notifications } from '@features/notifications';
import { Error } from './components/Error';
import { Loader } from './components/Loader';
import { Close } from './components/Close';
import { Sidebar } from './components/Sidebar';
import { Tour } from './components/Tour';
import { Arrow } from './components/Arrow';
import { Fullscreen } from './components/Fullscreen';

export const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const {
    loading: { processing, error },
    settings: { close },
  } = state;
  const fullScreenEl = useRef(null);
  const [isFullScreen, setFullScreen] = useFullScreen(fullScreenEl);

  const isDisabledArrows = false; // todo сделать
  const isDisabledFullscreen = false; // todo сделать

  const onClose = () => close();
  const onNext = () => dispatch(effects.setNext());
  const onPrev = () => dispatch(effects.setPrev());
  const onChangeFullScreen = () => setFullScreen();

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());
      await dispatch(getData.getPanoramas());
    })();

    if (!StatusInterfaces.checkAnyOneOpen(['cabinet', 'gallery'])) {
      notifications.init('tour-editor-notifications');
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Container ref={fullScreenEl}>
        <Wrapper isFullScreen={isFullScreen}>
          <Inner>
            {error && <Editor><Error error={error} /></Editor>}
            {(processing && !error) && <Editor><Loader /></Editor>}

            {(!processing && !error) && (
              <>
                <SideLeft isFullScreen={isFullScreen}><Arrow onClick={onPrev} direction="prev" disabled={isDisabledArrows} /></SideLeft>
                <SideRight isFullScreen={isFullScreen}><Arrow onClick={onNext} direction="next" disabled={isDisabledArrows} /></SideRight>

                <Editor>
                  <Sidebar />
                  <Tour />
                </Editor>
              </>
            )}

            <Controls isFullScreen={isFullScreen}>
              <Close onClose={onClose} />
              <Fullscreen onChangeFullScreen={onChangeFullScreen} disabled={isDisabledFullscreen} />
            </Controls>
          </Inner>
        </Wrapper>

        <div id="tour-editor-notifications" />
      </Container>
    </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  background-color: ${rgba(Colors.black, 0.4)};
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: ${({ isFullScreen }) => isFullScreen ? 0 : '60px 80px'};
  min-height: 650px;
`;
const Side = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  display: flex;
  opacity: ${({ isFullScreen }) => isFullScreen ? 0.8 : 1};
  align-items: center;
`;
const SideLeft = styled(Side)`
  right: ${({ isFullScreen }) => isFullScreen ? 'auto' : '100%'};
  left: ${({ isFullScreen }) => isFullScreen ? '10px' : 'auto'};
  margin-right: 10px;
`;
const SideRight = styled(Side)`
  left: ${({ isFullScreen }) => isFullScreen ? 'auto' : '100%'};
  right: ${({ isFullScreen }) => isFullScreen ? '10px' : 'auto'};
  margin-left: 10px;
`;
const Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const Editor = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  display: flex;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;
const Controls = styled.div`
  position: absolute;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
  opacity: ${({ isFullScreen }) => isFullScreen ? 0.8 : 1};
  top: ${({ isFullScreen }) => isFullScreen ? '10px' : '0'};
  left: ${({ isFullScreen }) => isFullScreen ? 'auto' : '100%'};
  right: ${({ isFullScreen }) => isFullScreen ? '10px' : 'auto'};
`;
