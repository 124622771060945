import { ON_ROUTE_CABINET } from '@observer/constants';
import { ApiCall } from './api-call';
import { LocalStorage } from 'libs';
import { POP_GROUP_BANNERS, POP_PROMO } from '@globalLocalStorage';

const observer = new window.POPObserver();

export const getUserSettings = async (team_id) => {
  try {
    const response = await ApiCall.getUserSettings({ team_id });

    const { data } = response;
    const { success } = data;

    if (!success) {
      throw new Error();
    }

    return data.data;
  } catch {
    return {};
  }
};

export const saveUserTariffSettings = async (team_id, tariff) => {
  // В случае, если чексумма успела измениться, забираем ее из ответа и делаем запрос снова
  // TODO переписать нормально с промисами
  const request = async (checksum) => {
    try {
      const response = await ApiCall.saveUserTariffSettings({ team_id, tariff, checksum });
      const {
        data: { success, errorText, data },
      } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);
        } else {
          throw new Error(errorText);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  };

  await request();
};

export const saveUserSettings = async (team_id, name, value) => {
  const request = async (checksum) => {
    try {
      const stringValue = JSON.stringify(value);
      const response = await ApiCall.saveUserSettings({ team_id, name, value: stringValue, checksum });
      const {
        data: { success, errorText, data },
      } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);
        } else {
          throw new Error(errorText);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  };

  await request();
};

export const getBanners = async (locale, group) => {
  const localGroupBanners = LocalStorage.get(POP_GROUP_BANNERS);

  try {
    if (group !== 'iinfopoints') {
      const localGroupBanner =
        localGroupBanners &&
        localGroupBanners.find((item) => {
          return item.locale === locale && item.group === group && item.expireAt > Date.now();
        });

      if (localGroupBanner && localGroupBanner.data) return localGroupBanner.data;
    }

    const response = await ApiCall.getBanners({ locale, group });
    const { data } = response;

    return data;
  } catch {
    return [];
  }
};

export const getLKBanners = async (locale, group) => {
  //const localGroupBanners = LocalStorage.get(POP_PROMO);
  //console.log('local' + localGroupBanners);
  try {
    const response = await ApiCall.getBanners({ locale, group });
    const { data } = response;

    return data;
  } catch {
    return [];
  }
};

export const getTariffs = async (locale) => {
  try {
    const response = await ApiCall.getTariffs({ locale });
    const { data } = response;
    const { success } = data;

    if (!success) {
      throw new Error();
    }

    return data;
  } catch {
    return [];
  }
};

export const getPricing = async (locale) => {
  try {
    const response = await ApiCall.getPricing({ locale });
    const { data } = response;
    const { success } = data;

    if (!success) {
      throw new Error();
    }

    return data;
  } catch {
    return [];
  }
};

export const routeInCabinet = (url) => {
  const route = url.split('/cabinet')[1];

  observer.postMessage(ON_ROUTE_CABINET, route);
};

export const routeInVideo = (url) => {
  if (window.EditorInvokeFunction) {
    window.EditorInvokeFunction('UrlOpen', url);
  }
};
