import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconDots } from '../../../components/icons';

export const EditIcon = ({ isOpen = false, disabled = false, onClick = () => {} }) => {
  return (
    <View disabled={disabled} isOpen={isOpen} onClick={() => !disabled && onClick(true)}>
      <IconDots fill={Colors.main_dark} />
    </View>
  );
};

const View = styled.div`
  border-radius: 4px;
  cursor: ${({ disabled }) => disabled  ? 'default' : 'pointer'};
  background-color: ${({ isOpen }) => isOpen ? Colors.main_beige : 'transparent'};
  opacity: ${({ disabled }) => disabled  ? 0.5 : 1};

  &:hover {
    background-color: ${({ disabled }) => disabled  ? 'transparent' : Colors.main_beige};
  }
  
  svg {
    display: block;
    stroke: ${Colors.main_dark} !important;
  }
`;