import { LocalStorage, StatusInterfaces } from '@libs';
import { POP_USER_SETTINGS, POP_PROFILE, POP_CURRENT_TEAM } from '@globalLocalStorage';
import { getBanners } from '@features/banners/modules/effects';
import Banners from '@features/banners';
import { TARIFF_ALIAS } from '@features/cabinet/constants';
import { saveUserSettings } from '@features/banners/modules/effects';
import { ON_OPEN_BANNER } from '@observer/constants';

const observer = new window.POPObserver();

export class DisplayInfoBanner {
  static async init({ locale = '' }) {
    const infoBanners = await this.getInfoBanners(locale);

    // Выводим баннер через несколько секунд что бы подождать возможный вывод других баннеров
    await new Promise(resolve => setTimeout(resolve, 3000));

    const showed = this.getShowedInfoBanners();
    const banners = infoBanners.filter((banner) => !showed.includes(banner.alias));

    // Проверяем открыт ли какой нибудь другой баннер
    if (StatusInterfaces.checkAnyOneOpen(['banners'])) {
      return;
    }

    this.getSuitableBanner(banners);
  }

  static getSuitableBanner = (banners, index = 0) => {
    const currentBanner = banners[index];

    // Проверяем подходить ли баннер под тариф пользователя
    if (!this.checkPassByTariff(currentBanner)) {
      if (banners[index + 1]) {
        return this.getSuitableBanner(banners, index + 1);
      }

      return;
    }

    if (currentBanner) {
      this.displayBanner(currentBanner);
      this.saveShowedBannersInSettings(currentBanner.alias);
    }
  }

  static checkPassByTariff(banner) {
    try {
      const profile = this.getProfile();
      const teamId = this.getTeamId();
      const bannerTariff = banner?.data?.tariff;
      let tariff = profile?.tariff?.alias;

      if (profile?.tariff?.is_trial) tariff = TARIFF_ALIAS.TRIAL_PRO;
      if (teamId) tariff = TARIFF_ALIAS.TEAM;
      if (!bannerTariff) return true;
      if (!tariff) return false;
      if (Array.isArray(bannerTariff)) return bannerTariff.includes(tariff);
      if (typeof bannerTariff === 'string') return bannerTariff === tariff;

      return false;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  static parseJson(value) {
    if (typeof value === 'object') {
      return value;
    }

    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  static saveShowedBannersInSettings(alias) {
    try {
      const settings = LocalStorage.get(POP_USER_SETTINGS);
      const showedInfoBanners = this.parseJson(settings?.unitySettings?.showedInfoBanners) || [];
      const editSettings = {
        ...settings,
        unitySettings: {
          ...settings?.unitySettings || {},
          showedInfoBanners: [...showedInfoBanners, alias],
        }
      };

      LocalStorage.set(POP_USER_SETTINGS, editSettings);
      saveUserSettings(0, 'showedInfoBanners', [...showedInfoBanners, alias]);
    } catch (error) {
      console.error(error);
    }
  }

  static displayBanner(banner) {
    observer.postMessage(ON_OPEN_BANNER, { banner });
  }

  static async getInfoBanners(locale) {
    try {
      const banners = await getBanners(locale, 'information');

      return banners;
    } catch (error) {
      console.error(error);

      return [];
    }
  }

  static getShowedInfoBanners() {
    try {
      const settings = LocalStorage.get(POP_USER_SETTINGS);
      const showedInfoBanners = this.parseJson(settings?.unitySettings?.showedInfoBanners) || [];

      return showedInfoBanners;
    } catch (error) {
      console.error(error);

      return [];
    }
  }

  static getProfile() {
    try {
      const profile = LocalStorage.get(POP_PROFILE);

      return profile;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
  
  static getTeamId() {
    try {
      const teamId = LocalStorage.get(POP_CURRENT_TEAM) || 0;

      return teamId;
    } catch (error) {
      console.error(error);

      return 0;
    }
  }
}
