import { translations } from '@libs';
import { MODE_ALIAS, UNITY, URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { getErrorMessage as getGlobalErrorMessage } from '@globalErrorMessages';
import { notifications } from '@features/notifications';
import { SendLog } from '@libs';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PAY,
  SET_PAYMENT_STATUS,
  SET_SUM_WITH_PROMO,
  PAY_STATUSES,
  SET_PROMO,
  SET_WIDGET,
  YOOKASSA,
  STRIPE,
  PAYPAL,
  REQUIRE,
} from '../../constants';
import { clearCart, saveLastBuy } from '../../modules/effects';
import { getErrorMessage } from '../../modules/get-error-message';
import { Analytics } from '../../modules/analytics';
import { ApiCall } from '../../modules/api-call';
import { extraFields } from './molecules/extra-fields';
import { checkInitialScreen, checkInitialCart } from '../../modules/effects';

const getPayKeys = (selected, fields, settings) => ({
  mode: selected.type === 'pay' ? selected.value : selected.type,
  binding_id: selected.type === 'card' || selected.type === 'bank_card' ? selected.value : '',
  receipt_email: fields.receipt_email.value,
  extra_data: extraFields[selected.value] ? fields.extra_data.value : '',
  promo: fields.promo.value,
  team_id: settings.team_id
});

export const getTotalPriceWithPromo = async (dispatch, state, { selected, fields, settings, cart }) => {
  if (!fields.promo.value) {
    dispatch({ type: SET_SUM_WITH_PROMO, payload: '' });
    dispatch({ type: SET_PROMO, payload: '' });
    return;
  }

  const { mode, binding_id, receipt_email, promo, team_id } = getPayKeys(selected, fields, settings);

  const products = cart.map((item) => {
    const amountOfSets = item.in_sets?.filter((itemSets) => itemSets.grouping !== null && itemSets.grouping !== '');

    return {
      'type[]': amountOfSets?.length ? amountOfSets[item.activeCount].store_id : item.store_id,
      'amount[]': item.view_count || '',
    }
  });

  const params = { preview: 1, mode, binding_id, receipt_email, promo, products, team_id };

  try {
    const response = await ApiCall.getPriceWithPromo({ params });
    const { data } = response;

    if (!data.success) {
      throw new Error(data.errorText);
    }

    // Показываем уведомление только если промокод, отличается от ранее установленного
    if (state.promo !== fields.promo.value) {
      const {
        active_from,
        active_to,
        discount,
      } = data.data;

      notifications.showSuccess(translations.t('store.promo.success'));
      Analytics.successUsePromo(fields.promo.value, active_from, active_to, discount);
    }

    dispatch({ type: SET_PROMO, payload: fields.promo.value });
    dispatch({ type: SET_SUM_WITH_PROMO, payload: data.data.price });
  } catch (error) {
    dispatch({ type: SET_SUM_WITH_PROMO, payload: '' });
    notifications.showError(getGlobalErrorMessage(error.message));
  }
};

export const getPay = async (dispatch, state, { selected, country, fields, settings, cart, price, vat, vatPercent, sumPromo }) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_PAY } });
  const { mode: modeApp, locale } = settings;
  const { mode, binding_id, receipt_email, extra_data, promo, team_id } = getPayKeys(selected, fields, settings);
  const method = { mode, binding_id };

  SendLog.sendPay({
    place: 'getPay',
    action: 'start',
    cart: cart.map(item => item.caption),
    method,
  });

  const products = cart.map((item) => {
    const amountOfSets = item.in_sets?.filter((itemSets) => itemSets.grouping !== null && itemSets.grouping !== '');
    return {
      'type[]': amountOfSets?.length ? amountOfSets[item.activeCount].store_id : item.store_id,
      'amount[]': item.view_count,
      'list_position[]': 0,
    }
  });

  const { unity_clientid } = settings;
  const platform = MODE_ALIAS[settings.mode] === UNITY ? 'standalone' : '';
  const return_url = encodeURIComponent(`${window.location.href.split('?')[0]}?orderid=%ORDER_ID%&store=1&locale=${locale}`);
  // for analytics
  const getTypeProducts = (items = []) => {
    const aliases = [];
    const sortingArr = ['tariff', 'render', 'panorama', 'widget'];

    items.forEach((item) => {
      if (!aliases.includes(item.view_alias)) {
        aliases.push(item.view_alias);
      }
    });

    aliases.sort(function(a, b){
      return sortingArr.indexOf(a) - sortingArr.indexOf(b);
    });

    return aliases.join(',');
  };
  const getAnalyticParams = () => {
    try {
      const sessionCookie = MODE_ALIAS[settings.mode] === UNITY ? '_ga_4TZBNGEL7D' : '_ga_5PVCN3YZZ9';

      return {
        client_id: window.gaGlobal && window.gaGlobal.vid,
        session_id: document.cookie.match(`(^|;)\\s*${sessionCookie}\\s*=\\s*([^;]+)`).pop().split('.')[2],
        session_number: document.cookie.match(`(^|;)\\s*_ga_4TZBNGEL7D\\s*=\\s*([^;]+)`).pop().split('.')[3],
      }
    }
    catch (error) {
      return {
        client_id: '',
        session_id: '' ,
      }
    }
  };

  Analytics.getPay(settings.mode, settings.locale, state, getTypeProducts(cart), promo);

  try {
    const analyticParams = getAnalyticParams();
    const params = { mode, binding_id, receipt_email, extra_data, promo, products, team_id, unity_clientid, platform, country, return_url, ...analyticParams };
    const response = await ApiCall.getPay({ params });
    const { data } = response;
    const { redirect, status, orderid, client_secret, confirmation_token, payment_method, publicKey, amount, client_id, currency_code } = data.data;
    const dataPaypal = { orderid, amount, client_id, currency_code };

    if (!data.success) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'no_success',
        req: data,
      });

      throw new Error(data.errorText);
    }

    /* Redirect */
    if (redirect) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'redirect',
        redirect: redirect,
      });

      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: redirect,
      });

      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.pending, orderid, method },
      });
      dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PAY } });
      
      return;
    }

    if (status === PAY_STATUSES.succeeded) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'success',
      });

      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.succeeded, cart: state.cart, method },
      });

      const last_buy = state.cart.map((item) => item.store_id);
      let cartCategories = '';

      try {
        const sortingArr = ['tariff', 'render', 'package', 'widget', 'panorama'];

        cartCategories = [...new Set(cart.map(item => item.view_alias))].sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b)).join(',');
      } catch (error) {
        console.error(error);
      }

      saveLastBuy(last_buy, state.checksum, dispatch);
      clearCart(dispatch, state);
      Analytics.statusPaySuccess(modeApp, locale, state, cartCategories);
    }

    if (status === PAY_STATUSES.pending) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'pending',
      });

      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.pending, orderid, method },
      });
    }

    if (status === PAY_STATUSES.processing) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'processing',
      });

      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.processing, orderid, method },
      });
    }

    if (confirmation_token) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'yookassa',
        method: mode,
      });

      dispatch({
        type: SET_WIDGET,
        payload: {screen: YOOKASSA, token: confirmation_token, method: mode, orderid}
      });
    }

    if (client_secret && publicKey) {
      if (status === PAY_STATUSES.requires_payment_method) {
        dispatch({
          type: SET_WIDGET,
          payload: {screen: REQUIRE, clientSecret: client_secret, publicKey, paymentMethod: payment_method}
        });
      } else {
        SendLog.sendPay({
          place: 'getPay',
          action: 'stripe',
          method: mode,
        });

        dispatch({
          type: SET_WIDGET,
          payload: {screen: STRIPE, clientSecret: client_secret, publicKey, orderid, price, vat, vatPercent, country, sumPromo, method: mode}
        });
      }
    }

    if (dataPaypal.client_id && dataPaypal.amount) {
      SendLog.sendPay({
        place: 'getPay',
        action: 'paypal',
      });

      dispatch({
        type: SET_WIDGET,
        payload: { screen: PAYPAL, ...dataPaypal }
      });
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PAY } });
  } catch (error) {
    console.error(error);
    SendLog.sendPay({
      place: 'getPay',
      action: 'throw',
      error: error.message,
    });
    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PAY } });
    notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
  }
};

export const checkStatusPay = async (dispatch, state, {orderid, redirectStatus, paymentIntent, paymentIntentClientSecret, tx_id}, counter = 0) => {
  const { settings, cart, payment: { method }} = state;
  const { locale, mode } = settings;
  let timeout;

  if (counter === 0) {
    SendLog.sendPay({
      place: 'checkStatusPay',
      action: 'start',
      cart: cart.map(item => item.caption),
      method,
    });
  }

  try {
    const response = await ApiCall.getStatusPay({ orderid, redirectStatus, paymentIntent, paymentIntentClientSecret, tx_id });

    const { data } = response;
    const { status, value } = data.data;
    const maxDelay = 15000;
    const delay = 2000;
    const attempts = 17;

    if (status === PAY_STATUSES.error || counter >= attempts) {
      clearCart(dispatch, state);

      SendLog.sendPay({
        place: 'checkStatusPay',
        action: 'error',
        attempts: counter,
        maxAttempts: counter >= attempts,
        req: data,
      });

      throw new Error(data.errorText);
    }

    if (status === PAY_STATUSES.wrong) {
        const statusValue = getErrorMessage(value, 'yookassa');

        checkInitialScreen(dispatch, state);
        checkInitialCart(dispatch, state, 'cart');

        SendLog.sendPay({
          place: 'checkStatusPay',
          action: 'wrong',
          status: statusValue,
        });

        dispatch({
            type: SET_PAYMENT_STATUS,
            payload: {
              status: PAY_STATUSES.wrong,
              value : statusValue,
              method,
            },
        });
        notifications.showError(translations.t('store.common.payment_wrong'));
    }

    if (status === PAY_STATUSES.unpaid) {
      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.unpaid, orderid, method },
      });

      timeout = setTimeout(() => {
        counter++;
        checkStatusPay(dispatch, state, {orderid, tx_id}, counter);
      }, delay * (counter + 1) > maxDelay ? maxDelay : delay * (counter + 1));
      return;
    }

    if (status === PAY_STATUSES.paid) {
      SendLog.sendPay({
        place: 'checkStatusPay',
        action: 'paid',
      });

      dispatch({
        type: SET_PAYMENT_STATUS,
        payload: { status: PAY_STATUSES.succeeded, cart, method },
      });
      notifications.showSuccess(translations.t('store.common.payment_success'));

      let cartCategories = '';

      try {
        const sortingArr = ['tariff', 'render', 'package', 'widget', 'panorama'];

        cartCategories = [...new Set(cart.map(item => item.view_alias))].sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b)).join(',');
      } catch (error) {
        console.error(error);
      }

      saveLastBuy(Object.keys(state.savedCart), state.checksum, dispatch); // при проверке статуса cart еще не доступна!
      clearCart(dispatch, state);
      Analytics.statusPaySuccess(mode, locale, state, cartCategories);
    }

    clearTimeout(timeout);
  } catch (error) {
    console.error(error);
    SendLog.sendPay({
      place: 'checkStatusPay',
      action: 'throw',
      error: error.message,
    });
    dispatch({
      type: SET_PAYMENT_STATUS,
      payload: { status: PAY_STATUSES.error, method },
    });
    notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
    clearTimeout(timeout);
  }
};
