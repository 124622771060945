import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import * as timeago from 'timeago.js';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import * as effects from '../effects';
import { onOpenQuickStoreWithProplus } from '../../../modules/effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { MODE_PROJECT, ROW_TYPE, HEADER_MODES } from '../constants';
import { ListEdit } from './listEdit';
import { IconLocked, IconPin } from '../../../components/icons';
import { getCorrectEditList, checkPermissionsFolder } from '../helpers';
import { Analytics } from '../../../modules/analytics';
import { EditIcon } from '../molecules/editIcon';

export const Project = ({
  project = {},
  editList = [],
  currentHeaderMode = '',
  onOpenProject = () => {},
  openGallery = () => {},
  openLink = () => {}
}) => {
  const dispatch = useDispatch();
  const { settings: { locale }} = useSelector(state => state);
  const [isOpenEditList, setIsOpenEditList] = useState(false);
  const [isChangeName, setIsChangeName] = useState(false);
  const isLocked = project.mode === MODE_PROJECT.view;
  const isPin = project.pinned_at;
  const selectActive = currentHeaderMode === HEADER_MODES.select;
  // Ищет ${} из name и заменяет на параметр project. ID ${id} --> ID 1234
  const editListCorrect = getCorrectEditList(editList, project);
  const disabledEdit = useMemo(() => !dispatch(checkPermissionsFolder()), []);

  const onChangeProjectName = (id, value) => {
    dispatch(effects.setProjectFolderName(id, value));
    setIsChangeName(true);
  };
  const onSaveProjectName = (id, value) => {
    if (isChangeName) {
      dispatch(effects.saveProjectName(id, value));
      setIsChangeName(false);
    }
  };
  const onSelected = () => {
    dispatch(effects.onSelect(project.id, ROW_TYPE.project, !Boolean(project.selected)));
  };
  const onOpen = () => {
    if(isLocked) {
      dispatch(onOpenQuickStoreWithProplus({ openProject: project.id }));
    } else {
      onOpenProject(project.id);
      Analytics.openProject(project.id);
    }
  };

  return (
    <View selectActive={selectActive} isSelected={project.selected}>
      {selectActive && <SelectedOverlay isSelected={project.selected} onClick={onSelected} />}

      <Preview isLocked={isLocked} onClick={onOpen}>
        <ToEdit>
          <ToEditText>{isLocked ? translations.t('cabinet.projects.view') : translations.t('cabinet.projects.edit')}</ToEditText>
        </ToEdit>

        {project.thumbnail ? <Image selectActive={selectActive} src={project.thumbnail} alt={project.name}/> : <FakeImg />}

        {isLocked &&
          <Locked>
            <IconLocked fill={Colors.main_blue} />
          </Locked>
        }

        {isPin &&
          <Pin>
            <IconPin />
          </Pin>
        }
      </Preview>

      <Info>
        <Name value={project.name}
              disabled={disabledEdit}
              onChange={(event) => onChangeProjectName(project.id, event.target.value)}
              onBlur={(event) => onSaveProjectName(project.id, event.target.value)}/>
        <Bottom>
          <Actions>
            <Action isDisabled={!project.has_gallery} onClick={() => openGallery(project.id)}>
              {translations.t('cabinet.projects.gallery')}
            </Action>
            {Boolean(project.widget_link) &&
              <Action onClick={() => openLink(project.widget_link)}>
                {translations.t('cabinet.projects.widget')}
              </Action>
            }
            {Boolean(project.estimate_link) &&
              <Action onClick={() => openLink(project.estimate_link)}>
                {translations.t('cabinet.projects.estimate')}
              </Action>
            }
          </Actions>

          <Edits>
            <LastEdit>{translations.t('cabinet.projects.edited')} {timeago.format(project.saved * 1000, locale)}</LastEdit>
            <Edit>
              <EditIcon disabled={disabledEdit} isOpen={isOpenEditList} onClick={() => setIsOpenEditList(true)} />

              {isOpenEditList &&
                <ListEdit id={project.id} list={editListCorrect} onClose={() => {setIsOpenEditList(false)}}/>
              }
            </Edit>
          </Edits>
        </Bottom>
      </Info>
    </View>
  )
};
const SelectedOverlay = styled.div`
  border: 4px solid ${({ isSelected }) => isSelected ? Colors.main_blue : 'transparent'};
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
`;
const View = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.light_gray};
  border-radius: 5px;
  position: relative;
  
  ${({ selectActive, isSelected }) => (selectActive && !isSelected) && `
    &:hover {
      ${SelectedOverlay} {
        border: 4px solid ${rgba(Colors.main_blue, 0.5)};
      }
    }
  `}
`;
const ToEdit = styled.div`
  ${Mixins.transition('opacity')};
  transition-duration: 150ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.main_blue};
  color: ${Colors.white};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  opacity: 0;
  z-index: 2;
`;

const Image = styled.img`
  opacity: ${({ selectActive }) => selectActive ? 0.5 : 1};
`;

const Preview = styled.div`
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  border-radius: 5px 5px 0 0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  &:hover {
    ${ToEdit} {
      opacity: 1;
    }
  }
  
  ${({ isLocked }) => isLocked && `
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${Colors.white};
      opacity: 0.5;
      z-index: 1;
    }
  `}
`;
const FakeImg = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${Colors.feldgrau25};
`;
const ToEditText = styled.div`
  font-size: 24px;
  line-height: 28px;
`;
const Locked = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 2;
`;
const Pin = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
`;
const Info = styled.div`
  padding: 8px 12px;
  flex-shrink: 0;
`;
const Name = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.main_dark} !important;
  margin-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  width: 100%;
  background: none;
  
  &:hover {
    border-bottom-color: ${Colors.feldgrau};
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Actions = styled.div`
  display: flex;
`;
const Action = styled.div`
  color: ${Colors.main_blue};
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
 
  &:not(:last-child) {
    margin-right: 8px;
  }
  
  &:hover {
    color: ${Colors.main_blue};
  }
  
  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;
    color: ${Colors.feldgrau50};
  `}
`;
const Edits = styled.div`
  display: flex;
  align-items: center;
`;
const LastEdit = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.light_gray};
  margin-right: 4px;
  margin-left: 8px;
`;
const Edit = styled.div``;